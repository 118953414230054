import { RefObject, createRef } from "react";
import { AboutNewWords, Container } from "src/components";
import { useStore } from "src/store";
import { WordsListWidget } from "src/components/WordsListWidget";

export const NewWords = ({
  rowRef,
}: {
  rowRef: RefObject<HTMLDivElement>;
}): JSX.Element => {
  const [newWords] = useStore((state) => [state.newWords]);
  const [timePeriod] = useStore((state) => [state.timePeriod]);
  const secondaryText: JSX.Element = (
    <>
      Words introduced <br /> in the past {timePeriod.replace("-", " ")}
    </>
  );

  const mainRef = createRef<HTMLDivElement>();

  const containerRef = () => {
    const screenSize = window.innerWidth;

    if (screenSize < 1024) return mainRef;
    return rowRef;
  };

  return (
    <Container className="flex flex-col gap-4">
      <span ref={mainRef}>
        <h2>new words</h2>
      </span>

      <WordsListWidget
        data={newWords}
        secondaryText={secondaryText}
        AboutContent={AboutNewWords}
        rowRef={containerRef()}
        newWords
      />
    </Container>
  );
};
