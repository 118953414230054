import { ColorBox } from '../ColorBox';

export const AboutLatestEarnings = () => (
    <div className="mt-4 grid gap-2 lgMobile:gap-16 mobile:gap-16 smMobile:gap-16 mb-4">
        <h5 className="mb-7 mobile:mb-12 smMobile:mb-10">Latest earnings</h5>
        <ColorBox
            color="bg-boxColors-100"
            type="widget"
            imageUrl={`${process.env.REACT_APP_PAGE_URL}images/seven_days_modal.png`}
            padding="px-2 py-2 lgMobile:pt-6 mobile:pt-3 smMobile:pt-6"
            iconSize="mdLaptop:h-42"
            text="Stars, gear items, and coins are a set of fun rewards that make up the incentive system in Word Tag and drive gameplay. But it’s not just a bit of fun: research shows that rewards have a powerful chemical effect in the brain that boosts game players’ motivation and engagement."
        />
        <ColorBox
            color="bg-boxColors-200"
            type="widget"
            imageUrl={`${process.env.REACT_APP_PAGE_URL}images/gear_modal.png`}
            padding="mdLaptop:px-2 py-4 lgMobile:pt-8 mobile:pt-8 smMobile:pt-12"
            iconSize="mdLaptop:right-auto"
            text={
                <>
                    <p className="text-modal">
                        In Word Tag, stars unlock a daily prize, new areas to explore, and vendors
                        where kids can customise their character with unique gear. Collecting
                        rewards drives kids to keep playing, learning more words as they smash Word
                        Tag challenges.
                    </p>
                </>
            }
        />
        <ColorBox
            color="bg-boxColors-300"
            type="widget"
            imageUrl={`${process.env.REACT_APP_PAGE_URL}images/items_modal.png`}
            padding="px-2 py-3 lgMobile:pt-6 mobile:pt-6 smMobile:pt-8"
            iconSize="mdLaptop:right-auto"
            text="Getting immediate feedback through reward collection gives kids an ego boost and helps them engage with challenging new material in an independent and positive way. They’re in the driver’s seat, and the destination is a bigger vocabulary (shhh…even if they might not think of it that way)."
        />
    </div>
);
