import React from "react";
import { useStore } from "src/store";

export const useUserData = () => {
  const [
    selectedGameUser,
    user,
    activityCompleted,
    fetchGameState,
    fetchGamesUsers,
    fetchReport,
    fetchContent,
    fetchAccount,
    timePeriod,
    fetchNewWords,
  ] = useStore((state) => [
    state.selectedGameUser,
    state.user,
    state.reports?.activityCompleted,
    state.fetchGameState,
    state.fetchGamesUsers,
    state.fetchReport,
    state.fetchContent,
    state.fetchAccount,
    state.timePeriod,
    state.fetchNewWords,
  ]);

  React.useEffect(() => {
    if (user?.id) fetchGamesUsers();
  }, [fetchGamesUsers, user?.id]);

  React.useEffect(() => {
    fetchAccount();
  }, [fetchAccount]);

  React.useEffect(() => {
    if (selectedGameUser?.guid && user?.id) {
      fetchReport();
      fetchGameState();
      fetchNewWords();
    }
  }, [
    fetchReport,
    fetchGameState,
    selectedGameUser,
    user?.id,
    timePeriod,
    fetchNewWords,
  ]);

  React.useEffect(() => {
    if (user?.id) fetchContent();
  }, [activityCompleted, fetchContent, user?.id]);
};
