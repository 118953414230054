import { ColorBox } from '../ColorBox';

export const AboutNewWords = () => (
    <div className="grid gap-4">
        <h5>Spectacular work!</h5>
        <span>
            <p>
                Your child has encountered so many new words in the last 7 days! See the full list
                below.
            </p>
        </span>
        <ColorBox
            color="bg-boxColors-400"
            imageUrl={`${process.env.REACT_APP_PAGE_URL}icons/red_word.png`}
            text={
                <>
                    <p>
                        Research shows that kids need several exposures to a word in order to learn
                        it. Your child&apos;s Word Tag adventure repeats every new word across
                        different contexts, including a word jumble game, a fill-in-the-blank
                        sentence game, and multiple-choice quiz rounds, to help reinforce its
                        meaning. Each context is enhanced by humour and vibrant settings to keep
                        players motivated and make the words more memorable.
                    </p>
                </>
            }
        />
        <p>
            You can help your child learn the words in this list by using them in conversation
            throughout the week. Hearing new words out loud is just one more new context in which
            they can “meet” a word!
        </p>
    </div>
);
