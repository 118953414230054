import { RefObject } from "react";
import { Box } from "src/components";
import { Content } from "src/types";
import { ItemList } from "../ItemList";

interface WordsListWidgetProps {
  data: Content[];
  secondaryText: JSX.Element;
  AboutContent?: React.ComponentType;
  rowRef?: RefObject<HTMLDivElement>;
  newWords?: boolean;
  collectedDate?: boolean;
}

export const WordsListWidget = (props: WordsListWidgetProps): JSX.Element => {
  const { data, secondaryText, AboutContent, rowRef, newWords, collectedDate } =
    props;

  return (
    <>
      <Box
        aboutcontent={AboutContent && <AboutContent />}
        modalContentStyle="lgMobile:max-h-[460px] mobile:max-h-[480px] smMobile:max-h-[460px] laptop:overflow-y-auto laptop:overflow-x-hidden"
        rowref={rowRef}
      >
        <div className="relative m-auto w-fit">
          <img
            className="w-18"
            src={`${process.env.REACT_APP_PAGE_URL}icons/red_word.png`}
            alt=""
          />

          {newWords && (
            <img
              className="absolute bottom-[10px] right-[5px]"
              src={`${process.env.REACT_APP_PAGE_URL}icons/new_badge.svg`}
              alt=""
            />
          )}
        </div>

        <h1 className="text-center">{data?.length ?? 0}</h1>
        <h5 className="text-center">{secondaryText}</h5>
      </Box>
      <ItemList data={data} collectedDate={collectedDate} />
    </>
  );
};
