import { RefObject, useEffect } from 'react';

export const useClickOutside = (
  modalRef: RefObject<HTMLDivElement> | undefined,
  toggleModal: () => void,
) => {
  const handleClickOutside = (event: any) => {
    if (modalRef?.current && !modalRef?.current.contains(event.target)) {
      toggleModal();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalRef]);
};
