import { RefObject, createRef } from "react";
import { AboutWordsOfWeek, Container } from "src/components";
import { useStore } from "src/store";
import { WordsListWidget } from "src/components/WordsListWidget";

export const WordsOfWeek = ({
  rowRef,
}: {
  rowRef: RefObject<HTMLDivElement>;
}): JSX.Element => {
  const [gameState] = useStore((state) => [state.gameState]);
  const data = gameState.wordsCompleted;
  const secondaryText: JSX.Element = (
    <>
      Total words <br /> Collected
    </>
  );

  const mainRef = createRef<HTMLDivElement>();

  const containerRef = () => {
    const screenSize = window.innerWidth;

    if (screenSize <= 1023) return mainRef;
    return rowRef;
  };

  return (
    <Container className="flex flex-col gap-4">
      <span ref={mainRef}>
        <h2>words in the bank</h2>
      </span>

      <WordsListWidget
        data={data}
        secondaryText={secondaryText}
        AboutContent={AboutWordsOfWeek}
        rowRef={containerRef()}
        collectedDate
      />
    </Container>
  );
};
