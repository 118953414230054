export const FREQUENTLY_ASKED_QUESTIONS = [
  {
    id: "ask-category-4",
    title: "General",
    questions: [
      {
        id: "ask-category-1-1",
        title: "What ages is Word Tag for?",
        description: (
          <div>
            <p>
              We generally recommend Word Tag for ages 7-12. However, there is
              no age limit on fun! Word Tag can easily be enjoyed by kids
              outside of the recommended age range if they have the reading
              capability and motor skills needed for the game.
            </p>
          </div>
        ),
      },
      {
        id: "ask-category-1-2",
        title: "Where is the app available?",
        description: (
          <p>
            Word Tag is available on both the Apple App Store and the Google
            Play Store. Happy adventuring!{" "}
          </p>
        ),
      },
      {
        id: "ask-category-1-3",
        title: "Is it a paid app?",
        description: (
          <p>
            You can trial Word Tag free of charge for a week. After this, there
            is a monthly subscription. The price varies depending on your
            currency, and there is a discount for a full year subscription.
          </p>
        ),
      },
      {
        id: "ask-category-1-4",
        title: "Is Word Tag safe for my child?",
        description: (
          <p>
            Yes. Safety is our number one priority. There are no external chat
            features, no in-app purchases, and no third-party advertising. All
            of this ensures a fun, safe gaming experience for everyone!
          </p>
        ),
      },
      {
        id: "ask-category-1-5",
        title: "How long should my child play for?",
        description: (
          <p>
            Word Tag is a fantastic game to promote safe, positive screen time.
            However, even good screen time should be limited. We recommend
            playing for 15-20 minutes each day, so the required word repetitions
            are encountered. The game is structured to limit the number of new
            words your child will encounter each day so they have the best
            chance of learning and understanding each one in the recommended
            15-20 minute slot. That’s potentially over 1,000 new words every
            year!
          </p>
        ),
      },
      {
        id: "ask-category-1-6",
        title: "What is the Mrs Wordsmith Word Engine?",
        description: (
          <div>
            <p>
              According to literacy experts, kids should learn words that they
              are likely to encounter frequently across different contexts. Our
              team of linguists and data scientists identify these words by
              mining databases of vocabulary found in major exams, school
              curricula, and classical literature.
            </p>
            <p>
              Our proprietary AI Word Engine classifies these lists according to
              their difficulty and topic, considers the context in which each
              word might appear, and assesses each word’s meaning difficulty
              based on its average age of acquisition and grade or year level.
              Players see words tailored to their abilities and needs.
            </p>
          </div>
        ),
      },
      {
        id: "ask-category-1-7",
        title: "Is there in-app advertising?",
        description: <p>There is no third-party advertising in Word Tag.</p>,
      },
    ],
  },
  {
    id: "asked-category-2",
    title: "Accounts and Subscriptions",
    questions: [
      {
        id: "ask-category-2-1",
        title: "How do I subscribe?",
        description: (
          <div>
            You have the option to subscribe to a monthly or annual plan. We
            will ask you to provide credit card and contact details when you
            first try the app. When you sign up, you will receive one free trial
            week, during which you may cancel at any time and not be charged!
          </div>
        ),
      },
      {
        id: "ask-category-2-2",
        title: "How do I cancel my subscription?",
        description: (
          <div className="grid gap-4">
            <p>
              We are sorry to hear you’ve decided to end your subscription! We
              hope you had an excellent epic word adventure during your time
              playing. Depending on your device’s operating system, there are
              two different processes.
            </p>
            <strong>On an Apple device:</strong>
            <ul>
              <li>- Open the Settings app</li>
              <li>- Tap your name</li>
              <li>- Tap Subscriptions</li>
              <li>- Tap the subscription you want to manage</li>
              <li>- Tap Cancel Subscription</li>
            </ul>
            <strong>On an Android device:</strong>
            <ul>
              <li>
                - Open the Google Play Store app and tap your profile icon in
                the top-right corner
              </li>
              <li>
                - In the Google menu that opens, tap Payments & Subscriptions
              </li>
              <li>
                - Tap Subscriptions, then find and select the membership that
                you want to cancel
              </li>
              <li>- Once its settings page opens, tap Cancel Subscription</li>
            </ul>
          </div>
        ),
      },
      {
        id: "ask-category-2-3",
        title: "Can I get a refund?",
        description: (
          <div className="grid gap-4">
            <p>
              Unfortunately, we can’t process a refund, as all payments go
              through the Apple or Google stores. However, you can contact them
              directly and follow the process here:
            </p>
            <p>
              For Apple iOS users go here:{" "}
              <a
                target="_blank"
                className="text-blue-900"
                rel="noopener noreferrer"
                href="https://support.apple.com/en-gb/HT204084"
              >
                https://support.apple.com/en-gb/HT204084
              </a>
            </p>
            <p>
              For Google Android users go here:{" "}
              <a
                target="_blank"
                className="text-blue-900"
                rel="noopener noreferrer"
                href="https://support.google.com/googleplay/answer/2479637?hl=en-GB"
              >
                https://support.google.com/googleplay/answer/2479637?hl=en-GB
              </a>
            </p>
          </div>
        ),
      },
    ],
  },
  {
    id: "ask-category-3",
    title: "Education",
    questions: [
      {
        id: "ask-category-3-1",
        title: "How is Word Tag educational?",
        description: (
          <div className="grid gap-4">
            <p>
              Although kids might not see Word Tag as a learning tool, they are
              improving their vocabulary with every play — even in as little as
              15 minutes a day.
            </p>
            <p>
              Word Tag introduces children to new words in bite-sized batches.
              This approach helps cement material better than plenty of
              information in one dose. Kids are then repeatedly exposed to these
              words, through activities that target all aspects of word
              learning. Mini-games cover a word's meaning, spelling,
              pronunciation, and usage with context. Your child will not only be
              exposed to new vocabulary; they will truly understand it.
            </p>
            <p>
              Word-learning is the key to success in the game. Building their
              vocabulary by collecting new words is the mechanism by which kids
              progress to the next level, win prizes, and explore. The dopamine
              release in kids’ brains reinforces their learning experience
              positively. This enjoyment keeps them coming back to play (and
              therefore learn) more and more!
            </p>
          </div>
        ),
      },
      {
        id: "ask-category-3-2",
        title: "How does Word Tag teach vocabulary?",
        description: (
          <div className="grid gap-4">
            <p>One word: Gamification.</p>
            <p>
              Word Tag unites learning and fun through gameplay by immersing
              kids in an open-world adventure where they are able to interact
              with over 1000 words per year, personalized to their individual
              learning level. Word Tag is designed so kids learn new vocabulary
              as they earn rewards.
            </p>
            <ul className="grid gap-4">
              <li>
                <strong>Active engagement:</strong>
                <br />
                Word Tag engages kids with new words. Retention significantly
                increases when kids enjoy their learning experience.
              </li>
              <li>
                <strong>Word repetition:</strong>
                <br />
                According to research, learners need several encounters with a
                word to add it to their vocabulary. Word Tag repeats word
                exposure enough times to guarantee retention.
              </li>
              <li>
                <strong>Learning progression: </strong>
                <br />
                Research also demonstrates that games can provide constructive
                feedback and a feeling of accomplishment. Word Tag allows
                players to try until they succeed, tailoring content to skill
                level.
              </li>
              <li>
                <strong>Self-directed learning: </strong>
                <br />
                Free play and exploration are crucial for learners’ cognitive
                development, cultivating curiosity and a desire to learn. In
                Word Tag, players work at their own pace to complete word
                collections full of rich vocabulary. They get to choose how long
                and how often they want to play (and learn)!
              </li>
              <li>
                <strong>Character-based learning:</strong>
                <br />
                Interacting with fictional characters like Roxy and Wolfgang is
                educationally and psychologically beneficial for kids. A child
                is more likely to retain new information when it is presented to
                them by a character.
              </li>
            </ul>
          </div>
        ),
      },
      {
        id: "ask-category-3-3",
        title: "Can Word Tag be used in school/ in lessons?",
        description: (
          <div className="grid gap-4">
            <p>
              Word Tag focuses on building vocabulary and uses repeated exposure
              to new words through mini-games that cover a word's meaning,
              spelling, pronunciation, and usage with context. This makes it a
              perfect supplementary tool for English lessons!{" "}
            </p>
            <p>
              Because we recommend about 15 minutes of daily play, you could
              incorporate some gameplay time into your lesson plan OR you could
              set 15 minutes as homework (and maybe have the kids report back
            </p>
            <p>
              (It is an individual gameplay experience that is meant to be
              tailored to each child, so Word Tag isn’t well-suited to be a
              singular-screen group activity.)
            </p>
          </div>
        ),
      },
      {
        id: "ask-category-3-4",
        title: "What if my child finds their words too easy/ hard?",
        description: (
          <div className="grid gap-4">
            <p>
              Currently, you cannot re-adjust your levels within the app. If you
              are finding that the words being served to your child are
              unsuitable for their reading level, you will have to delete Word
              Tag, reinstall, and start the game over.{" "}
            </p>
            <p>
              We are releasing updates later in the year that will allow level
              adjustment after starting the game. Stay tuned!{" "}
            </p>
          </div>
        ),
      },
    ],
  },
  {
    id: "ask-category-5",
    title: "Progress Report",
    questions: [
      {
        id: "ask-category-5-1",
        title: "Can I see how my child is doing?",
        description: (
          <p>
            Yes, absolutely! Paid subscriptions come with a full report, updated
            daily, of how each child or children are doing, and their progress
            in the game. We will show you the words your child or children are
            learning, and the pace at which they are working. We also outline
            what you can help them with at home (if you so choose). You can
            access this report through the game or on a web browser.
          </p>
        ),
      },
      {
        id: "ask-category-5-2",
        title: "What is the progress report?",
        description: (
          <div className="grid gap-4">
            <p>
              Great question! The progress report is a customized and secure
              browser-based report which shows you how your child is progressing
              at Word Tag. We will show you the words they are learning and the
              pace at which they are working. We also outline what you can help
              them with at home. You can view this whenever you want, either
              through the app or on any browser. Information is updated daily.
            </p>
            <p>
              For a view of multiple profiles, the home subscription allows you
              to see the progress of up to four child profiles.
            </p>
          </div>
        ),
      },
    ],
  },
  {
    id: "ask-category-5",
    title: "Tech Support",
    questions: [
      {
        id: "ask-category-5-5",
        title: "Why is the game crashing?",
        description: (
          <div className="grid gap-4">
            <p>
              We are so sorry to hear the game is crashing. Fortunately, there
              is often a simple, easily-resolved reason behind errors like this!
              Word Tag is unable to support certain older devices (with less
              than 1Gb of RAM) or older operating systems. If you find that the
              game is repeatedly crashing, it could be that you are using one of
              these devices. These include:
            </p>
            <ul>
              <li>Apple</li>
              <li>iPhone 6 or older</li>
              <li>iPad 4th generation or older</li>
              <li>iPad Mini 3 or older</li>
              <li>Android</li>
              <li>Android OS 4 or older</li>
              <li>Any other device with less than 1Gb of RAM</li>
            </ul>
            <p>
              If you’re still having problems getting into Word Tag, please
              reach out to us at wordtaginfo@mrswordsmith.com and we can help
              troubleshoot. For this, please include information about the
              device and operating system you are using, as well any screenshots
              of error messages.
            </p>
          </div>
        ),
      },
    ],
  },
];
