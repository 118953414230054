import { createRef, RefObject, useCallback, useState } from "react";
import clsx from "clsx";
import { useCookies } from "react-cookie";

import Popup from "reactjs-popup";
import { Button, Modal, Box } from "src/components";
import {
  AboutGamesPerformance,
  AboutLatestEarnings,
  AboutStarsCollected,
  AboutUserHeader,
  AboutWhy,
  AboutWordsCollected,
  AboutWordsOfWeek,
} from "src/components/Structures/AboutModal";
import { StoreButtons } from "../StoreButtons";

import { useStore } from "src/store";

import { validateEmail } from "src/utils";

import "./HeaderNav.scss";
import { ModalContent, ModalFooter } from "./components";

const MODALS_MAX_INDEX = 5;
const MODALS_MIN_INDEX = 0;

export const HeaderNav = ({
  modalAuthRef,
}: {
  modalAuthRef: RefObject<HTMLDivElement>;
}): JSX.Element => {
  const [message, user, setModalOpen, fetchAccessEmail] = useStore((state) => [
    state.reports.message,
    state.user,
    state.setModalOpen,
    state.fetchAccessEmail,
  ]);

  const [step, setStep] = useState(MODALS_MIN_INDEX);
  const [authModalType, setAuthModalType] = useState<
    "newUser" | "existingUser" | "emailSent"
  >("existingUser");
  const [userEmail, setUserEmail] = useState("");
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [open, setOpen] = useState(false);
  const [openLogout, setOpenLogout] = useState(false);
  const [openWhy, setOpenWhy] = useState(false);

  const { "2": removeCookie } = useCookies();

  const modalGuideTourRef = createRef<HTMLDivElement>();

  const toggleAuthModal = useCallback(() => {
    setOpen(true);
    setModalOpen();
  }, [setModalOpen]);

  const toggleWhyModal = useCallback(() => {
    setOpenWhy(!openWhy);
    setModalOpen();
  }, [openWhy, setModalOpen]);

  const toggleLogoutModal = useCallback(() => {
    setOpenLogout(!openLogout);
    setModalOpen();
  }, [openLogout, setModalOpen]);

  const closeAuthModal = useCallback(() => {
    setAuthModalType("existingUser");
    setOpen(false);
    setModalOpen();
  }, [setModalOpen]);

  const submitSignin = useCallback(() => {
    const emailIsValid = validateEmail(userEmail);
    if (!emailIsValid) return setInvalidEmail(true);
    fetchAccessEmail(userEmail);
    setAuthModalType("emailSent");
  }, [fetchAccessEmail, userEmail]);

  const submitLogout = useCallback(() => {
    removeCookie("access_token", {
      domain: window.location.host,
    });
    window.location.reload();
  }, [removeCookie]);

  const renderModalContent = useCallback(() => {
    const modals = [
      <AboutUserHeader />,
      <AboutLatestEarnings />,
      <AboutWordsCollected />,
      <AboutStarsCollected />,
      <AboutWordsOfWeek />,
      <AboutGamesPerformance />,
    ];
    return modals[step];
  }, [step]);

  const nextStep = () =>
    setStep((prevState) =>
      prevState < MODALS_MAX_INDEX ? prevState + 1 : MODALS_MAX_INDEX
    );

  const previousStep = () =>
    setStep((prevState) =>
      prevState > MODALS_MIN_INDEX ? prevState - 1 : MODALS_MIN_INDEX
    );

  const renderNavItems = () => {
    return (
      <>
        <Button
          as="a"
          href="/?pages=faqs"
          rel="noopener noreferrer"
          target="_self"
        >
          FAQs
        </Button>
        {user?.id && (
          <Button
            icon={`${process.env.REACT_APP_PAGE_URL}icons/mail.png`}
          >{`All Messages (${message.length})`}</Button>
        )}
        {user?.id ? (
          <Button onClick={toggleLogoutModal} variant="light">
            Logout
          </Button>
        ) : (
          <Button onClick={toggleAuthModal} variant="light">
            Login
          </Button>
        )}
      </>
    );
  };

  return (
    <>
      <div className={clsx({ "header-nav_demo": !user?.id }, "header-nav p-2")}>
        <div className="header-nav_container container mx-auto gap-4 p-2 flex items-center justify-between">
          <div className="header-nav_container_alerts">
            {user?.id ? (
              <div className="inline-flex gap-2 md:gap-4 items-center play-alert p-2 md:p-4 justify-between">
                <div className="flex gap-4 items-center">
                  <span className="header-nav_demo_alert  flex items-center justify-center">
                    <img
                      src={`${process.env.REACT_APP_PAGE_URL}icons/alert.svg`}
                      alt=""
                    />
                  </span>
                  <strong className="text-sm">
                    Play every day for the best results!
                  </strong>
                </div>
                <Button onClick={toggleWhyModal}>Why?</Button>
              </div>
            ) : (
              <div className="flex flex-col md:flex-row items-center ">
                <strong className="flex gap-2 md:gap-4 items-center p-2 md:p-4">
                  <span className="header-nav_demo_alert  flex items-center justify-center">
                    <img
                      src={`${process.env.REACT_APP_PAGE_URL}icons/alert.svg`}
                      alt=""
                    />
                  </span>
                  <span className="text-center text-white lg:text-left">
                    This is a demo page. Want your own report? Get the Word Tag
                    game on
                  </span>
                </strong>
                <StoreButtons className="hidden lg:flex" />
              </div>
            )}
          </div>
          <nav className="hidden lg:block header-nav_container_nav">
            <div className="flex gap-4 px-4 py-1">
              {renderNavItems()}
              <span className="w-1" />
            </div>
          </nav>
          <div className="block lg:hidden">
            <Popup
              trigger={(isOpen) => (
                <span
                  className={clsx(
                    "h-full menu-button rounded-xl flex items-center justify-center",
                    { active: isOpen }
                  )}
                >
                  <img
                    src={`${process.env.REACT_APP_PAGE_URL}icons/${
                      isOpen ? "close" : "menu-icon"
                    }.svg`}
                    alt=""
                  />
                </span>
              )}
              position="bottom right"
              offsetY={-8}
            >
              <Box className="rounded-tr-none grid grid-flow-row gap-2">
                {renderNavItems()}
                <hr className="my-2" />
                <StoreButtons className="flex-col" />
              </Box>
            </Popup>
          </div>
        </div>
      </div>
      <Modal
        open={openLogout}
        onClose={toggleLogoutModal}
        contentStyle="max-w-containerModal"
        anchorRef={modalAuthRef}
        footer={
          <div className="flex gap-4 justify-between pr-4">
            <Button
              className="flex-grow justify-center"
              variant="light"
              onClick={toggleLogoutModal}
            >
              No
            </Button>
            <Button className="flex-grow justify-center" onClick={submitLogout}>
              Yes
            </Button>
          </div>
        }
      >
        <div className="text-center">
          <h2 className="mb-8">Logout</h2>
          <p className="max-w-fit mb-8 whitespace-nowrap">
            Are you sure you want to quit?
          </p>
        </div>
      </Modal>
      <Modal
        open={openWhy}
        onClose={toggleWhyModal}
        contentStyle="max-w-containerModal"
        anchorRef={modalAuthRef}
      >
        <AboutWhy />
      </Modal>
      <Modal
        open={open}
        contentStyle="max-w-containerModal"
        anchorRef={modalGuideTourRef}
        onClose={() => setStep(0)}
        footer={
          <div className="flex justify-between gap-2">
            {step > MODALS_MIN_INDEX && (
              <Button variant="light" onClick={previousStep}>
                Previous
              </Button>
            )}
            {step < MODALS_MAX_INDEX && (
              <div className="flex flex-grow justify-end">
                <Button onClick={nextStep}>Next</Button>
              </div>
            )}
          </div>
        }
      >
        {renderModalContent()}
      </Modal>
      <Modal
        open={open}
        anchorRef={modalAuthRef}
        footer={
          <ModalFooter
            authModalType={authModalType}
            closeAuthModal={closeAuthModal}
            submitSignin={submitSignin}
            setAuthModalType={setAuthModalType}
          />
        }
        onClose={closeAuthModal}
        contentStyle="max-w-md"
      >
        <ModalContent
          authModalType={authModalType}
          invalidEmail={invalidEmail}
          setUserEmail={setUserEmail}
          setInvalidEmail={setInvalidEmail}
        />
      </Modal>
    </>
  );
};
