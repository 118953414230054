import { ColorBox } from "../ColorBox";

export const AboutUserHeader = () => (
  <div className="grid gap-4">
    <h5>Welcome to your control panel!</h5>
    <p>
      Discover your child&apos;s avatar of choice. Are they playing as Roxy the
      fun-loving fox, or Wolfgang the wily wolf? Either way, they made an
      excellent choice if we do say so ourselves.
    </p>
    <p>
      <strong className="text-modal">Total Game Earnings</strong> highlight your
      child&apos;s impressive in-game achievements so far. They're acing this!
      Remember, they can&apos;t earn anything without boosting their vocabulary
      through Word Games. Here&apos;s how:
    </p>
    <ul className="grid gap-4">
      <li>
        <ColorBox
          color="bg-boxColors-100"
          imageUrl={`${process.env.REACT_APP_PAGE_URL}icons/star.png`}
          text={
            <>
              <strong className="text-modal">Stars</strong> are awarded for
              completing Word Games and allow players to unlock game areas,
              gear, and different playable characters. The more words they
              learn, the more Stars they earn!
            </>
          }
        />
      </li>
      <li>
        <ColorBox
          color="bg-boxColors-200"
          imageUrl={`${process.env.REACT_APP_PAGE_URL}icons/coin.png`}
          text={
            <>
              <strong className="text-modal">Coins</strong> are collected by
              playing games and exploring the game&apos;s maps. They're used to
              purchase awesome in-game items.
            </>
          }
        />
      </li>
      <li>
        <ColorBox
          color="bg-boxColors-300"
          imageUrl={`${process.env.REACT_APP_PAGE_URL}icons/gear.png`}
          text={
            <>
              <strong className="text-modal">Gear Items</strong> are the number
              of things collected or bought in-game. These can be accessories,
              outfits, or characters! Ask your child what they&apos;ve picked up
              recently.
            </>
          }
        />
      </li>
    </ul>
    <p>
      <ColorBox
        color="bg-boxColors-400"
        imageUrl={`${process.env.REACT_APP_PAGE_URL}icons/new_message.png`}
        iconSize="left-0"
        text={
          <>
            <strong className="text-modal">View Messages</strong> shows you any
            new messages or updates from the Word Tag team.
          </>
        }
      />
    </p>
  </div>
);
