import { ReactNode } from "react";
import GameItemsJSON from "src/assets/gamedata.json";

export interface GameEvent {
  eventId: string;
  eventDt: string;
  type: GameEventTypes;
  data: {
    id?: string;
    message?: string;
    subject?: string;
    sendDt?: string;
    startDt?: string;
    endDt?: string;
    interactionEventId?: string;
    wordId?: string;
    activity?: ContentReportTypes;
    totalAmount?: number;
    item?: {
      type: number;
      id: keyof typeof GameItemsJSON.items;
    };
    attemptCount?: number;
    success?: boolean;
  };
}

export interface GameState {
  currentCharacter: AvatarKey;
  starCount: number;
  coinCount: number;
  canCount: number;
  gearCount: number;
  wordsCompletedCount: number;
  wordsInProgressCount: number;
  wordsCompleted: Array<Content>;
  wordsInProgress: Array<Content>;
}

export interface Content {
  id: string;
  completedAt?: string;
  word: string;
  partOfSpeech: string;
  definition: string | null;
  lexileScore: number;
  synonyms: {
    word: string;
  }[];
  audio: {
    uri: string;
    locale: string;
  }[];
  success?: boolean;
  attemptCount?: number;
  activity?: ContentReportTypes;
  eventDt?: string;
}

export interface WordList {
  minimumCompletionTime: number;
  words: {
    id: string;
    partOfSpeech: string;
    word: string;
  }[];
  type: string;
  name: string;
  id: string;
  author: string;
}

export interface NewAssignment {
  userIds?: string[];
  endDt: Date;
  startDt: Date;
  wordListId: string;
  name: string;
}

export interface AssignmentHistory {
  users: {
    [user_guid: string]: {
      averageTimePlayedPerDay: number;
      wordCompletionCount: number;
      successOnFirstAttemptCount: number;
      gamesPlayedCount: number;
    };
  };
  minimumExpectedGamesPlayedCount: number;
  minimumExpectedPlayTimePerDay: number;
  expectedSuccessOnFirstAttemptCount: number;
  expectedWordCompletionCount: number;
}

export interface User {
  id: number;
  email: string;
}

export interface GameUser {
  guid: string;
  name: string;
}

export interface NewWords {
  wordId: string;
}

export interface PopQuizByDate {
  [key: string]: Content[];
}

export type GameEventTypes =
  | "message"
  | "starReceived"
  | "timePlayed"
  | "sessionStarted"
  | "activityCompleted"
  | "itemReceived"
  | "achievementEarned"
  | "missionFinished"
  | "missionStarted"
  | "missionAborted"
  | "wordCompleted";

export type Report = { [key in GameEventTypes]: GameEvent[] };

export type ContentReportTypes =
  | "AssembleSyllables"
  | "MatchCollocations"
  | "MatchSentenceBlank"
  | "MatchSynonyms"
  | "PopQuiz"
  | "PopQuizSynonym"
  | "PopQuizCollocation";

export type ContentReport = { [key in ContentReportTypes]: GameEvent[] };

export type ContentData = { [x: string]: Content };

export type ContentWords = {
  AssembleSyllables: GameEvent[];
  MatchCollocations: GameEvent[];
  MatchSentenceBlank: GameEvent[];
  MatchSynonyms: GameEvent[];
  PopQuiz: GameEvent[];
  PopQuizSynonym: GameEvent[];
  PopQuizCollocation: GameEvent[];
};

export type ContentWordsTypes = keyof ContentWords;

export const AvatarImage = {
  Character_Barksy: `${process.env.REACT_APP_PAGE_URL}avatar/Character_Barksy.png`,
  Character_Monica: `${process.env.REACT_APP_PAGE_URL}avatar/Character_Monica.png`,
  Character_Roxy: `${process.env.REACT_APP_PAGE_URL}avatar/Character_Roxy.png`,
  Character_Smashtag: `${process.env.REACT_APP_PAGE_URL}avatar/Character_Smashtag.png`,
  Character_Wolfgang: `${process.env.REACT_APP_PAGE_URL}avatar/Character_Wolfgang.png`,
};

const wordJumble = {
  title: "Word Jumble",
  description:
    "Learn the spelling and pronunciation of a word by placing its jumbled parts in the right order. Then see a kid-friendly definition that introduces the meaning of the word.",
  icon: `${process.env.REACT_APP_PAGE_URL}icons/wordsCompleted.png`,
};
const synonymsOrWordPaires = {
  title: "Synonyms or Word Pairs",
  description:
    "Learn more about a word's meaning by selecting synonyms (words with similar meaning) or word pairs (words that can be used together with the main word).",
  icon: `${process.env.REACT_APP_PAGE_URL}icons/can.png`,
};
const wordsInContext = {
  title: "Words in Context",
  description:
    "Learn to use words in context by choosing the right word to complete engaging sentences of different genres.",
  icon: `${process.env.REACT_APP_PAGE_URL}icons/scissors.png`,
};
const popQuiz = {
  title: "Pop Quiz",
  description:
    "Reinforce learning by selecting synonyms or word pairs in a fast-paced quiz.",
  icon: `${process.env.REACT_APP_PAGE_URL}icons/lamp.png`,
};

export const ContentKeyData = {
  AssembleSyllables: wordJumble,
  MatchCollocations: synonymsOrWordPaires,
  MatchSentenceBlank: wordsInContext,
  MatchSynonyms: synonymsOrWordPaires,
  PopQuiz: popQuiz,
  PopQuizSynonym: popQuiz,
  PopQuizCollocation: popQuiz,
};

export type AvatarKey = keyof typeof AvatarImage;

export type Period =
  | "day"
  | "days"
  | "week"
  | "weeks"
  | "month"
  | "months"
  | "year"
  | "years";

export type WordWidgetListProps = {
  label: string;
  detail?: string;
};

export type AssignmentProps = {
  assignment: Assignment;
  header?: ReactNode;
};

export enum Status {
  active = "active",
  completed = "completed",
  pending = "pending",
}

export type Assignment = {
  userIds: string[];
  endDt: Date;
  startDt: Date;
  wordListId: string;
  name: string;
  id: string;
};

export type IconProps = {
  src: string;
  alt: string;
  className?: string;
};

export type WordWidgetProps = {
  aboutContent?: ReactNode;
  title: string;
  subtitle: string;
  btnLabel: string;
  btnVariant: "dark" | "light";
  onClick: () => void;
  icon: IconProps;
  wordsCompleted: number;
  wordsTotal: number;
  startDt: Date;
  endDt: Date;
};

export type StatisticWidgetProps = {
  completionScore: number;
  completionTotal: number;
  completionExpectedScore: number;
  accuracyScore: number;
  accuracyTotal: number;
  accuracyExpectedScore: number;
  timeScore: number;
  timeTotal: number;
  timeExpectedScore: number;
};
