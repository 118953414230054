import "./InitialBanner.scss";

export const InitialBanner = (): JSX.Element => {
  const imgURL = `${process.env.REACT_APP_PAGE_URL}images/logo.png`;

  return (
    <div className="initial-banner p-8 hidden lg:block">
      <div
        className="initial-banner_container container mx-auto flex align-middle py-8"
        style={{
          background: `url(${imgURL}) no-repeat`,
        }}
      >
        <div className="flex flex-col justify-center initial-banner_title">
          <h1 className="mb-6">WELCOME TO THE WORD TAG PROGRESS REPORT!</h1>
          <p className="font-medium mb-4">
            You may be thinking, "Scooting? Stars? Swag? There's no way my child
            is actually learning anything!" Well, welcome to our game-based
            learn-to-earn method.
          </p>
          <p className="font-medium">
            Discover what your child has learned to earn all this loot. The more
            words they master, the more Stars, Coins, and Gear they earn! This
            is no ordinary school report card. Click on the ABOUT buttons to
            learn more about each section.
          </p>
        </div>
      </div>
    </div>
  );
};
