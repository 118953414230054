import axios, { AxiosRequestConfig } from "axios";
import moment from "moment";
import * as Sentry from "@sentry/react";
import {
  Content,
  GameEvent,
  GameState,
  GameUser,
  User,
  Period,
  NewWords,
  WordList,
  NewAssignment,
  AssignmentHistory,
  Assignment,
} from "src/types";

const API_URL =
  process.env.NODE_ENV === "development"
    ? `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`
    : `api/${process.env.REACT_APP_API_VERSION}`;

class ApiService {
  async request(url: string, config?: AxiosRequestConfig<any>) {
    try {
      const response = await axios(url, {
        withCredentials: true,
        ...config,
      });
      return await response.data;
    } catch (error: any) {
      Sentry.captureException(error);

      throw error.message;
    }
  }

  async getAccount(): Promise<User> {
    return this.request(`${API_URL}/account`);
  }

  async getUserData(): Promise<GameUser[]> {
    return this.request(`${API_URL}/users`);
  }

  async getGameState(userId: string): Promise<GameState> {
    return this.request(`${API_URL}/jupiter/report/${userId}/game-state`);
  }

  async getReport(userId: string, timePeriod: string): Promise<GameEvent[]> {
    const timePeriodAmount = timePeriod.split("-")[0];
    const timePeriodMeasure = timePeriod.split("-")[1] as Period;

    return this.request(`${API_URL}/jupiter/report/v2/${userId}`, {
      params: {
        after: moment()
          .subtract(timePeriodAmount, timePeriodMeasure)
          .toISOString(),
        before: moment().toISOString(),
      },
    });
  }

  async getContent(wordsId: string[]): Promise<Content[]> {
    return this.request(`${API_URL}/jupiter/report/content`, {
      data: wordsId,
      method: "POST",
    });
  }

  async getAccessEmail(email: string): Promise<void> {
    return this.request(`${API_URL}/jupiter/report/access-email`, {
      headers: {
        email,
      },
    });
  }

  async getNewWords(userId: string, timePeriod: string): Promise<NewWords[]> {
    const timePeriodAmount = timePeriod.split("-")[0];
    const timePeriodMeasure = timePeriod.split("-")[1] as Period;

    return this.request(`${API_URL}/jupiter/report/${userId}/new-words`, {
      params: {
        after: moment()
          .subtract(timePeriodAmount, timePeriodMeasure)
          .toISOString(),
        before: moment().toISOString(),
      },
    });
  }

  async getWordListById(wordListId: string): Promise<WordList> {
    return this.request(`${API_URL}/jupiter/wordlist/${wordListId}`);
  }

  async getWordlists({
    type,
    name,
    author,
  }: {
    type?: string;
    name?: string;
    author?: string;
  }): Promise<WordList[]> {
    return this.request(`${API_URL}/jupiter/wordlist`, {
      params: {
        type,
        name,
        author,
      },
    });
  }

  async getAssignmentById(assignmentId: string): Promise<Assignment> {
    return this.request(`${API_URL}/jupiter/assignment/${assignmentId}`);
  }

  async getAssignments({
    userGuid,
    name,
    type,
  }: {
    userGuid: string;
    type?: string;
    name?: string;
  }): Promise<Assignment[]> {
    return this.request(`${API_URL}/jupiter/assignment`, {
      params: {
        user_id: userGuid,
        type,
        name,
      },
    }).then((res) => res.assignments);
  }

  async getAssignmentHistory(
    assignmentId: string,
    userGuid?: string,
    active?: boolean
  ): Promise<AssignmentHistory> {
    return this.request(
      `${API_URL}/jupiter/assignment/${assignmentId}/history`,
      {
        params: {
          user_guid: userGuid,
          active,
        },
      }
    );
  }

  async startAssignment({
    endDt,
    name,
    startDt,
    userIds,
    wordListId,
  }: NewAssignment): Promise<void> {
    return this.request(`${API_URL}/jupiter/assignment`, {
      data: {
        endDt,
        name,
        startDt,
        userIds,
        wordListId,
      },
      method: "POST",
    });
  }

  async finishAssignment(
    assignmentId: string,
    {
      endDt,
      name,
    }: {
      endDt: string;
      name: string;
    }
  ): Promise<void> {
    return this.request(`${API_URL}/jupiter/assignment/${assignmentId}`, {
      data: {
        endDt,
        name,
      },
      method: "PATCH",
    });
  }
}

export const API = new ApiService();
