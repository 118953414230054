import clsx from "clsx";
import moment from "moment";
import React, { createRef, useCallback, useState } from "react";
import { Box, Modal, NoInfoGames } from "src/components";
import { useStore } from "src/store";
import { PopQuizByDate } from "src/types";
import { ItemList } from "../ItemList";
import "../ItemList/ItemList.scss";
import { PopQuizLine } from "./components/PopQuizLine";

interface PopQuizItemListProps extends React.HTMLAttributes<HTMLDivElement> {
  popQuizData: PopQuizByDate;
}

export const PopQuizItemList = (props: PopQuizItemListProps): JSX.Element => {
  const modalRef = createRef<HTMLDivElement>();
  const [setModalOpen] = useStore((state) => [state.setModalOpen]);
  const [selectedPopQuizDate, setSelectedPopQuizDate] = useState<
    string | undefined
  >();
  const [selectedSuccessRate, setSelectedSuccessRate] = useState<number>(0);

  const { popQuizData, className } = props;

  const itemClick = useCallback(
    (event: string | undefined, successRate: number) => {
      setSelectedPopQuizDate(event);
      setSelectedSuccessRate(successRate);
      setModalOpen();
    },
    [setModalOpen]
  );

  return Object.keys(popQuizData).length ? (
    <>
      <div className="relative" ref={modalRef}>
        <Box className={clsx("item-list_container p-0 relative", className)}>
          <div className="grid item-list_container_content gap-2 pb-2 min-h-full">
            {Object.keys(popQuizData)
              .sort((a, b) => moment(b).diff(moment(a)))
              .map((event) => {
                const success = popQuizData[event].filter(
                  (e) => e.success
                ).length;
                const total = popQuizData[event].length;

                const successRate = success / total;

                return (
                  <div
                    key={event}
                    onClick={() => itemClick(event, successRate)}
                    className={clsx(
                      "item-list flex items-center justify-between p-2",
                      {
                        check: successRate >= 0.7,
                      },
                      {
                        alert: successRate < 0.7,
                      }
                    )}
                  >
                    <div className="flex items-center gap-2 w-full">
                      <PopQuizLine event={event} successRate={successRate} />
                    </div>
                  </div>
                );
              })}
          </div>
        </Box>
      </div>

      <Modal
        anchorRef={modalRef}
        open={!!selectedPopQuizDate}
        onClose={() => itemClick(undefined, 0)}
        className=""
      >
        <div className="inline-flex flex-col gap-2">
          <div className="flex items-center gap-2 w-full mb-3">
            <PopQuizLine
              event={selectedPopQuizDate ?? ""}
              successRate={selectedSuccessRate}
            />
          </div>
          <div className="flex items-center gap-1">
            <ItemList
              data={popQuizData[selectedPopQuizDate ?? ""]}
              className="shadow-none !p-0 w-max h-min"
              isPopQuiz
            />
          </div>
        </div>
      </Modal>
    </>
  ) : (
    <Box className={clsx("item-list h-64", className)}>
      <NoInfoGames />
    </Box>
  );
};
