import { Box, NoInfoGames } from "src/components";
import GameItemsAssets from "src/assets/gamedata.json";
import { ItemType } from "src/modules/Home/types";
import { formatDate } from "src/utils";
import { useStore } from "src/store";
import clsx from "clsx";

export const EarnedItems = ({
  modal = false,
}: {
  modal?: boolean;
}): JSX.Element => {
  const [reports] = useStore((state) => [state.reports]);

  const earnedItems = reports.itemReceived.filter(
    (item) => item.data.item?.type !== ItemType.Currency
  );

  if (!earnedItems) {
    return (
      <Box className="earned-items">
        <NoInfoGames />
      </Box>
    );
  }

  return (
    <Box
      className={clsx("h-full", {
        "modal-earned-items": modal,
        "earned-items": !modal,
      })}
    >
      <div
        className={clsx("gap-3", {
          "modal-earned-items_container": modal,
          "earned-items_container": !modal,
        })}
      >
        {earnedItems.map((item, index) => (
          <div
            key={`${item.data.id} ${index}`}
            className={clsx(
              "flex flex-col items-center gap-2 justify-between",
              {
                "pb-2 pt-2 modal-earned-items_container_item": modal,
                "p-2 pb-4 pt-8 earned-items_container_item": !modal,
              }
            )}
          >
            <div className="image-icon">
              {item.data.item?.id &&
                GameItemsAssets.items[item.data.item.id]?.icon && (
                  <img
                    src={`${process.env.REACT_APP_PAGE_URL}items/${
                      GameItemsAssets.items[item.data.item.id].icon
                    }`}
                    alt={GameItemsAssets.items[item.data.item.id].id}
                  />
                )}
            </div>
            <div className="text-center pt-3">
              {item.data.item?.type && (
                <h6>
                  {GameItemsAssets.items[item.data.item.id]?.name ??
                    ItemType[item.data.item.type]}
                </h6>
              )}
              <p className="text-xs">{formatDate(item.eventDt)}</p>
            </div>
          </div>
        ))}
      </div>
    </Box>
  );
};
