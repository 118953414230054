import { PropsWithChildren, RefObject, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

interface PortalProps {
  wrapperRef: RefObject<HTMLDivElement> | undefined;
}

export const Portal = ({
  children,
  wrapperRef,
}: PropsWithChildren<PortalProps>) => {
  const [wrapperElement, setWrapperElement] = useState<HTMLElement | null>(
    null,
  );

  useEffect(() => {
    setWrapperElement(wrapperRef?.current || null);
  }, [wrapperRef]);

  if (wrapperElement === null) return null;

  return createPortal(children, wrapperElement);
};
