import React from "react";
import clsx from "clsx";

import { Box } from "../Structures";

import "./Collapsable.scss";

interface CollapsableProps {
  children: React.ReactNode;
  title: string;
}

export const Collapsable = (props: CollapsableProps): JSX.Element => {
  const [collapsed, setCollapsed] = React.useState(false);

  const { children, title } = props;

  return (
    <Box
      className="collapsable-container pl-6  cursor-pointer"
      onClick={() => setCollapsed(!collapsed)}
    >
      <div
        className={clsx("collapsable-container_title flex justify-between", {
          "collapsable-container_title_active": collapsed,
          "collapsable-container_title_inactive": !collapsed,
        })}
      >
        {title}
      </div>
      <div
        className={clsx(
          {
            collapsable_active: collapsed,
          },
          "collapsable-container_content"
        )}
      >
        <p className="mt-4">{children}</p>
      </div>
    </Box>
  );
};
