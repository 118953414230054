import React, { RefObject, useCallback, useState } from "react";
import { Popup } from "reactjs-popup";

import {
  AboutUserHeader,
  Box,
  Button,
  Container,
  Modal,
  Tag,
} from "src/components";

import { useStore } from "src/store";
import { formatDate } from "src/utils";

import { AvatarImage, GameEvent, GameUser } from "src/types";

import "./UserHeader.scss";

export const UserHeader = ({
  modalAuthRef,
}: {
  modalAuthRef: RefObject<HTMLDivElement>;
}): JSX.Element => {
  const [
    reports,
    selectedGameUser,
    gameUsers,
    setGameUser,
    gameState,
    setModalOpen,
  ] = useStore((state) => [
    state.reports,
    state.selectedGameUser,
    state.gameUsers,
    state.setGameUser,
    state.gameState,
    state.setModalOpen,
  ]);

  const [openUserPopup, setOpenUserPopup] = useState(false);
  const [openViewMessages, setOpenViewMessages] = useState(false);
  const [viewMessage, setViewMessage] = useState<GameEvent>();
  const [open, setOpen] = useState(false);

  const openMessage = useCallback(
    (message: GameEvent) => {
      setViewMessage(message);
      setOpen(true);
      setModalOpen();
    },
    [setModalOpen]
  );

  const toggleModal = useCallback(() => {
    setOpen(!open);
    setModalOpen();
  }, [open, setModalOpen]);

  const selectProfile = (user: GameUser) => {
    setGameUser(user);
    toggleShowUserPopup();
  };

  const toggleShowUserPopup = () => setOpenUserPopup((prevState) => !prevState);

  const toggleViewMessagesPopup = () =>
    setOpenViewMessages((prevState) => !prevState);

  const renderEarningType = React.useCallback(
    (key: keyof typeof gameState) => {
      if (!gameState) return null;
      const icon = key.replace(/Count/g, "");
      return (
        <div className="flex flex-col">
          <div className="category-item px-4 py-2 flex justify-center items-center">
            <img
              className="mb-2"
              src={`${process.env.REACT_APP_PAGE_URL}icons/${icon}.png`}
              alt={key}
            />
          </div>
          <span className="inline-flex justify-center -mt-2">
            <Tag>{gameState[key].toString()}</Tag>
          </span>
        </div>
      );
    },
    [gameState]
  );

  const getUserList = React.useCallback(
    (users: GameUser[]): GameUser[] => {
      const allUsers = [...gameUsers];
      const currentUser = users.findIndex(
        (user) => user?.guid === selectedGameUser?.guid
      );
      if (currentUser !== -1) allUsers.splice(currentUser, 1);
      return allUsers;
    },
    [gameUsers, selectedGameUser?.guid]
  );

  const allUsers = getUserList(gameUsers);

  const renderPartialSubject = (subject: string) => {
    if (subject.length > 25) return `${subject.substring(0, 25)}...`;
    else return subject;
  };

  return (
    <Container
      rowref={modalAuthRef}
      className="user-header rounded-none md:pb-1 md:pt-1"
      aboutcontent={<AboutUserHeader />}
      modalContentStyle="laptop:max-h-[600px] mdLaptop:max-h-[600px] tablet:max-h-[600px] lgMobile:max-h-[350px] mobile:max-h-[360px] smMobile:max-h-[360px]  laptop:overflow-y-auto laptop:overflow-x-hidden"
      lightButton
    >
      <div className="user-header_container container md:px-8 py-4 mx-auto gap-4">
        <div className="avatar-container flex flex-col justify-end">
          <div className="avatar-container_content h-100 w-100 -mb-3">
            <img
              className="back"
              src={`${process.env.REACT_APP_PAGE_URL}images/back_avatar.svg`}
              alt=""
            />
            <img
              className="avatar"
              src={AvatarImage[gameState.currentCharacter]}
              alt={gameState.currentCharacter}
            />
            <div className="nickname">
              <h1 className="gradient">{selectedGameUser?.name}</h1>
              <h1 className="gradient-shadow">{selectedGameUser?.name}</h1>
              <h1 className="white-shadow">{selectedGameUser?.name}</h1>
            </div>
          </div>
          {allUsers.length > 0 && (
            <div className="change-profile flex justify-center z-10">
              <Popup
                open={openUserPopup}
                trigger={() => (
                  <span className="">
                    <Button onClick={toggleShowUserPopup}>
                      Change profile
                    </Button>
                  </span>
                )}
                position="bottom center"
                closeOnDocumentClick={false}
              >
                <Box className="inline-flex flex-col gap-2">
                  <button onClick={toggleShowUserPopup}>
                    <img
                      className="w-8 absolute right-0 top-0 -mt-4 -mr-4"
                      src={`${process.env.REACT_APP_PAGE_URL}icons/close.svg`}
                      alt=""
                    />
                  </button>

                  {allUsers?.map((user) => (
                    <Box
                      onClick={() => selectProfile(user)}
                      key={user.guid}
                      className="py-2 pl-2"
                    >
                      <div className="flex gap-2 items-center">
                        <Box className="rounded-full p-0 w-8">
                          <img
                            className=""
                            src={`${process.env.REACT_APP_PAGE_URL}avatar/sm_wolf.png`}
                            alt=""
                          />
                        </Box>
                        <strong>{user.name}</strong>
                      </div>
                    </Box>
                  ))}
                </Box>
              </Popup>
            </div>
          )}
        </div>

        <div className="earnings-container p-4 pb-6 text-center">
          <h2 className="mb-6">total game earnings</h2>
          <div className="flex flex-row gap-2 md:gap-4 justify-center">
            {renderEarningType("starCount")}
            {renderEarningType("coinCount")}
            {renderEarningType("gearCount")}
          </div>
        </div>
        <div className="messages-container flex flex-col items-center justify-center p-2 sm:p-4 gap-4 ">
          <div>
            <img
              src={`${process.env.REACT_APP_PAGE_URL}images/message.png`}
              alt=""
            />
          </div>
          <Popup
            open={openViewMessages}
            trigger={() => (
              <span className="inline-block absolute z-10 bottom-0 p-3">
                <Button
                  className="w-max"
                  onClick={toggleViewMessagesPopup}
                  disabled={reports?.message.length === 0}
                >
                  View Messages
                </Button>
              </span>
            )}
            position="bottom center"
            closeOnDocumentClick={false}
            disabled={reports?.message.length === 0}
          >
            <Box className="flex flex-col gap-2">
              <button onClick={toggleViewMessagesPopup}>
                <img
                  className="w-8 absolute right-0 top-0 -mt-4 -mr-4"
                  src={`${process.env.REACT_APP_PAGE_URL}icons/close.svg`}
                  alt=""
                />
              </button>

              {reports?.message?.map((item) => (
                <Box
                  className="px-4 p-2"
                  key={item.eventId}
                  onClick={() => openMessage(item)}
                >
                  <div className="flex gap-4 items-center">
                    <img
                      src={`${process.env.REACT_APP_PAGE_URL}icons/new_message.png`}
                      alt=""
                    />
                    <div className="flex flex-col">
                      <strong>{item.data.message}</strong>{" "}
                      <span className="text-xs">
                        {item.data.subject &&
                          renderPartialSubject(item.data.subject)}
                      </span>
                    </div>
                  </div>
                </Box>
              ))}
            </Box>
          </Popup>
        </div>
      </div>
      <Modal open={open} onClose={toggleModal} anchorRef={modalAuthRef}>
        {viewMessage && (
          <div className="flex flex-col gap-2 pb-4">
            <img
              className="w-10 mb-2"
              src={`${process.env.REACT_APP_PAGE_URL}images/message.png`}
              alt="message"
            />
            <h6>
              {viewMessage.data.message}{" "}
              <span className="text-xs font-light">
                {formatDate(viewMessage.eventDt)}
              </span>{" "}
            </h6>
            <p>{viewMessage.data.subject}</p>
          </div>
        )}
      </Modal>
    </Container>
  );
};
