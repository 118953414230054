import React from "react";
import clsx from "clsx";

import "./DonutChart.scss";

interface DonutChartProps extends React.HTMLAttributes<HTMLDivElement> {
  score: number;
  total: number;
  middleNumber?: number;
  middleText?: string;
  expectedScore?: number;
  theme?: "dark" | "light";
}

export const DonutChart = (props: DonutChartProps): JSX.Element => {
  const {
    score,
    total,
    theme = "light",
    middleNumber,
    middleText,
    expectedScore,
    className,
  } = props;

  const percentage = React.useMemo(
    () => ((score / total) * 100).toFixed(),
    [score, total]
  );

  const expectedScorePercentage = React.useMemo(
    () => ((expectedScore || 0) / total) * 100,
    [expectedScore, total]
  );

  const expectedScoreAngle = React.useMemo(
    () => (expectedScorePercentage / 100) * 360,
    [expectedScorePercentage]
  );

  return (
    <div
      className={clsx(`doughnut-chart p${percentage}`, theme, className)}
      style={
        {
          "--expected-score-angle": `${expectedScoreAngle}deg`,
        } as React.CSSProperties
      }
    >
      <span>
        <span>
          {middleNumber} <br /> <p className="text-[10px]">{middleText}</p>
        </span>
      </span>
      <div className="slice">
        {expectedScore && (
          <div
            className={clsx("expected-dot", `p${expectedScorePercentage}`)}
          />
        )}
        <div className="bar" />
        <div className="fill" />
      </div>
    </div>
  );
};
