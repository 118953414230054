import clsx from "clsx";

import "./Tag.scss";

interface TagProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  icon?: "check" | "alert";
}

export const Tag = (props: TagProps): JSX.Element => {
  const { children, icon, className } = props;

  return (
    <div
      {...props}
      className={clsx("tag px-3", className, {
        "pr-12": !!icon,
      })}
    >
      {icon && (
        <div className="icon-tag absolute top-0 bottom-0 m-auto -right-5 flex justify-center items-center z-10">
          <img
            src={`${process.env.REACT_APP_PAGE_URL}icons/${icon}.svg`}
            alt={icon}
          />
        </div>
      )}
      <span className="opacity-0">{children}</span>
      <span className="fix-index-children flex items-center">{children}</span>
    </div>
  );
};
