import clsx from "clsx";
import React, { createRef, useCallback, useState } from "react";
import { Box, Modal, NoInfoGames } from "src/components";
import { useStore } from "src/store";
import { Content } from "src/types";
import "./ItemList.scss";
import moment from "moment";

interface ItemListProps extends React.HTMLAttributes<HTMLDivElement> {
  data: Content[];
  collectedDate?: boolean;
  isGameWidget?: boolean;
  isPopQuiz?: boolean;
}

export const ItemList = (props: ItemListProps): JSX.Element => {
  const modalRef = createRef<HTMLDivElement>();
  const [words, setModalOpen] = useStore((state) => [
    state.words,
    state.setModalOpen,
  ]);
  const [selectedWord, setSelectedWord] = useState<Content | undefined>();

  const { data, collectedDate, isGameWidget, isPopQuiz, className } = props;

  const playAudio = useCallback((url: string) => {
    const audio = new Audio(url);
    audio.play();
  }, []);

  const itemClick = useCallback(
    (event: Content | undefined) => {
      setSelectedWord(event);
      setModalOpen();
    },
    [setModalOpen]
  );

  const currentWord = (word?: Content) => {
    return word?.word ? word : word?.id ? words[word.id] : ({} as Content);
  };

  const word = currentWord(selectedWord);

  return data?.length ? (
    <>
      <div className="relative" ref={modalRef}>
        <Box className={clsx("item-list_container relative", className)}>
          <div
            className={clsx(
              "grid item-list_container_content gap-2 pb-2 min-h-full lgMobile:p-4 mobile:p-4 mdMobile:p-4",
              isPopQuiz && "grid-cols-2"
            )}
          >
            {data.map((event) => {
              const isSuccessCondition =
                isGameWidget && event.attemptCount && event.attemptCount < 3;
              const isNeedsPracticeCondition =
                isGameWidget && event.attemptCount && event.attemptCount >= 3;

              return (
                <div
                  key={event.id}
                  onClick={() => itemClick(event)}
                  className={clsx(
                    "item-list flex items-center justify-between p-2",
                    isPopQuiz && "p-0",
                    {
                      check: isSuccessCondition || (isPopQuiz && event.success),
                    },
                    {
                      alert:
                        isNeedsPracticeCondition ||
                        (isPopQuiz && !event.success),
                    }
                  )}
                >
                  <div className="flex items-center gap-2 w-full">
                    <img
                      className="w-9"
                      src={`${process.env.REACT_APP_PAGE_URL}icons/red_word.png`}
                      alt=""
                    />
                    <div className="flex flex-col w-full center">
                      <div className="flex justify-between">
                        <h6 className="self-center">
                          {currentWord(event)?.word}
                        </h6>
                        {(isSuccessCondition ||
                          (isPopQuiz && event.success)) && (
                          <img
                            className="w-6 h-6"
                            src={`${process.env.REACT_APP_PAGE_URL}icons/check.svg`}
                            alt="check"
                          />
                        )}
                        {(isNeedsPracticeCondition ||
                          (isPopQuiz && !event.success)) && (
                          <img
                            className="w-6 h-6"
                            src={`${process.env.REACT_APP_PAGE_URL}icons/alert.svg`}
                            alt="alert"
                          />
                        )}
                      </div>
                      {collectedDate && event.completedAt && (
                        <p className="text-xs">
                          {moment(event.completedAt).format("DD MMM YYYY")}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </Box>
      </div>
      <Modal
        anchorRef={modalRef}
        open={!!selectedWord}
        onClose={() => itemClick(undefined)}
      >
        <div className="inline-flex flex-col gap-2 pb-4">
          <div className="flex items-center gap-1">
            <img
              className="-ml-2 w-12"
              src={`${process.env.REACT_APP_PAGE_URL}icons/red_word.png`}
              alt=""
            />
            <div>
              <h6>{word?.word}</h6>
              <p className="text-xs">{word?.partOfSpeech}</p>
            </div>
          </div>
          {word?.definition && (
            <div>
              <strong className="text-xs">Definition</strong>
              <p className="-mt-1 text-sm">{word.definition}</p>
            </div>
          )}
          {word?.synonyms?.length > 0 && (
            <div>
              <strong className="text-xs">Synonymous</strong>
              <p className="-mt-1 text-sm">
                {word.synonyms.map(
                  (synonym, index) =>
                    `${synonym.word}${
                      index === word.synonyms.length - 1 ? "" : ", "
                    }`
                )}
              </p>
            </div>
          )}
          <div
            onClick={() => playAudio(word.audio[0].uri)}
            className="hover: cursor-pointer"
          >
            <img
              src={`${process.env.REACT_APP_PAGE_URL}icons/sound.png`}
              alt=""
            />
          </div>
        </div>
      </Modal>
    </>
  ) : (
    <Box className={clsx("item-list h-64", className)}>
      <NoInfoGames />
    </Box>
  );
};
