import moment from "moment";
import isEmail from "validator/lib/isEmail";

export function formatDate(date: string, format = "MMM DD YYYY") {
  return moment(date).format(format);
}

export function validateEmail(email: string) {
  return isEmail(email);
}
