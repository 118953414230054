import { createRef, useMemo } from "react";
import {
  AboutGamePopQuiz,
  AboutGameSynonymsOfWordPairs,
  AboutGameWordJumble,
  AboutGameWordsInContext,
  Container,
} from "src/components";
import { GameWidget } from "src/components/GameWidget";
import { useStore } from "src/store";
import { PopQuizByDate } from "src/types";

export const WorkOnWordGames = (): JSX.Element => {
  const modalRef = createRef<HTMLDivElement>();
  const icon = (
    <img
      className="-ml-2 w-12"
      src={`${process.env.REACT_APP_PAGE_URL}icons/red_word.png`}
      alt=""
    />
  );

  const [words] = useStore((state) => [state.words]);

  const data = Object.values(words);

  const wordJumble = useMemo(
    () => data.filter((word) => word.activity === "AssembleSyllables"),
    [data]
  );

  const wordsInContext = useMemo(
    () => data.filter((word) => word.activity === "MatchSentenceBlank"),
    [data]
  );

  const synonymsOfWordPairs = useMemo(
    () =>
      data.filter(
        (word) =>
          word.activity === "MatchCollocations" ||
          word.activity === "MatchSynonyms"
      ),
    [data]
  );

  const popQuizData = useMemo(
    () =>
      data.filter(
        (word) =>
          word.activity === "PopQuiz" ||
          word.activity === "PopQuizSynonym" ||
          word.activity === "PopQuizCollocation"
      ),
    [data]
  );

  const groupedData = popQuizData.reduce<PopQuizByDate>((groups, obj) => {
    const date = obj.eventDt?.split("T")[0] ?? "";
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(obj);
    return groups;
  }, {});

  return (
    <Container className="flex justify-between flex-col">
      <div ref={modalRef}>
        <h2 className="mb-6 w-full">Work on Word Games</h2>
        <div className="flex w-100 gap-6 mb-6 smMobile:flex-wrap mobile:flex-wrap">
          <GameWidget
            icon={icon}
            title={"Word Jumble"}
            aboutContent={<AboutGameWordJumble />}
            data={wordJumble}
            wrapperRef={modalRef}
          />
          <GameWidget
            icon={icon}
            title={"Words in Context"}
            aboutContent={<AboutGameWordsInContext />}
            data={wordsInContext}
            wrapperRef={modalRef}
          />
        </div>
        <div className="flex w-100 gap-6 smMobile:flex-wrap mobile:flex-wrap">
          <GameWidget
            icon={icon}
            title={"Synonyms & Word Pairs"}
            aboutContent={<AboutGameSynonymsOfWordPairs />}
            data={synonymsOfWordPairs}
            wrapperRef={modalRef}
          />
          <GameWidget
            icon={icon}
            title={"Pop Quiz"}
            aboutContent={<AboutGamePopQuiz />}
            data={popQuizData}
            wrapperRef={modalRef}
            popQuizData={groupedData}
          />
        </div>
      </div>
    </Container>
  );
};
