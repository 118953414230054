export const AboutGamePopQuiz = () => {
  return (
    <div className="grid md:grid-cols gap-4 py-4 mb-4">
      <div>
        <h5 className="mb-3">Pop Quiz</h5>
        <p className="mb-4">
          Nothing like a Pop Quiz in school, The Pop Quizzes in WordTag is where all the learning
          comes together in a gamified format
        </p>
        <p className="mb-4">
          The Pop Quizes your child encounters, reinforce word meaning by choosing synonyms or word
          pairs for different words in a fast-paced quiz!
        </p>
        <video width="100%" controls>
          <source src={`${process.env.REACT_APP_PAGE_URL}videos/quiz.mp4`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};
