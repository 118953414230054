import { Box, Container, AboutStarsCollected } from "src/components";
import { useStore } from "src/store";
import { GraphLineBar } from "../GraphLineBar";

import "./StarsCollected.scss";
import { createRef } from "react";

export const StarsCollected = (): JSX.Element => {
  const [starReceived] = useStore((state) => [state.reports.starReceived]);
  const [timePeriod] = useStore((state) => [state.timePeriod]);

  const mainRef = createRef<HTMLDivElement>();

  return (
    <Container className="stars-collected text-center md:text-left">
      <div ref={mainRef}>
        <h2 className="text-center md:text-left mb-6">
          stars collected and minutes played
        </h2>
        <div className="stars-collected_container gap-4">
          <Box
            className="flex flex-col items-center justify-center"
            aboutcontent={<AboutStarsCollected />}
            modalContentStyle="laptop:max-h-[500px] mdLaptop:max-h-[350px] tablet:max-h-[600px] lgMobile:max-h-[650px] smMobile:max-h-smModal mobile:max-h-[580px]  laptop:overflow-y-auto laptop:overflow-x-hidden"
            rowref={mainRef}
          >
            <img
              src={`${process.env.REACT_APP_PAGE_URL}icons/star.png`}
              alt=""
            />
            <h1>{starReceived.length}</h1>
            <h6 className="mb-3 text-center">
              Stars collected for acing Word Games in the past
              <br />
              {timePeriod.replace("-", " ")}
            </h6>
          </Box>
          <GraphLineBar timePeriod={timePeriod} />
        </div>
      </div>
    </Container>
  );
};
