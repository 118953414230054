import clsx from "clsx";
import { Button } from "src/components/Button";
import { StoreButtons } from "src/components/Structures/StoreButtons";

interface ModalContentProps {
  authModalType: string;
  invalidEmail: boolean;
  setUserEmail: (e: string) => void;
  setInvalidEmail: (val: boolean) => void;
}

interface ModalFooterProps {
  authModalType: string;
  closeAuthModal: () => void;
  submitSignin: () => void;
  setAuthModalType: (val: "newUser" | "existingUser" | "emailSent") => void;
}

export const ModalContent = ({
  authModalType,
  invalidEmail,
  setUserEmail,
  setInvalidEmail,
}: ModalContentProps): JSX.Element => {
  switch (authModalType) {
    case "newUser":
      return (
        <div className="text-center">
          <h2 className="mb-8">Get the app</h2>
          <p className="max-w-fit mb-8">
            To create a new account you must download the Wordtag game and
            create a profile.
          </p>
          <StoreButtons />
        </div>
      );
    case "existingUser":
      return (
        <div className="text-center">
          <h2 className="mb-8">do you already have word tag installed?</h2>
          <p className="max-w-fit mb-8">
            If you already have a Word Tag account, log in to request your own
            report containing your child's progress to your email. Don't have a
            Word Tag account? Get the app!
          </p>
          <div className="text-left">
            <input
              className={clsx("w-full", {
                "border-red-500 text-red-500": invalidEmail,
              })}
              type="text"
              placeholder="Account Email"
              onChange={(e) => setUserEmail(e.target.value)}
              onFocus={() => setInvalidEmail(false)}
            />
            {invalidEmail && (
              <span className="text-xs pl-3 font-bold text-red-500">
                Invalid email
              </span>
            )}
          </div>
        </div>
      );
    default:
      return (
        <div className="text-center">
          <h2 className="mb-8">email sent</h2>
          <p className="max-w-screen-sm mb-8">
            You will receive an email with your access, don't forget to check
            your spam in case you don't find it.
          </p>
        </div>
      );
  }
};

export const ModalFooter = ({
  authModalType,
  closeAuthModal,
  submitSignin,
  setAuthModalType,
}: ModalFooterProps): JSX.Element => {
  switch (authModalType) {
    case "emailSent":
      return (
        <div className="flex justify-center pr-4">
          <Button variant="dark" onClick={closeAuthModal}>
            I understood
          </Button>
        </div>
      );
    case "newUser":
      return (
        <div className="flex justify-center">
          <Button
            variant="light"
            onClick={() => setAuthModalType("existingUser")}
          >
            I'm already a customer
          </Button>
        </div>
      );
    default:
      return (
        <div className="flex gap-4 justify-between pr-4">
          <Button
            className="flex-grow"
            onClick={() => setAuthModalType("newUser")}
          >
            Get the app
          </Button>
          <Button className="flex-grow" onClick={submitSignin}>
            Log in
          </Button>
        </div>
      );
  }
};
