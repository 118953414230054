import './store';
import './style.scss';

import clsx from 'clsx';
import { createRef, useMemo } from 'react';
import { useLocation } from 'react-router';
import { HeaderNav, InitialBanner, PageContainer } from './components';
import { CreateAssignment, FaqPage, HomePage, PastAssignments } from './modules';
import { UserHeader } from './modules/Home/pages/HomePage/components';

import { useStore } from './store';

export const App = () => {
    const [userLoading, gameUsersLoading, reportsLoading, gameStateLoading, modalOpen] = useStore(
        (state) => [
            state.userLoading,
            state.gameUsersLoading,
            state.reportsLoading,
            state.gameStateLoading,
            state.modalOpen
        ]
    );
    const { search } = useLocation();

    const featureFlagAssignments = process.env.REACT_APP_FEATURE_ASSIGNMENTS === 'true';

    const isLoading = userLoading || gameUsersLoading || reportsLoading || gameStateLoading;

    const modalAuthRef = createRef<HTMLDivElement>();

    const RouteComponent = useMemo(() => {
        if (search.includes('pages=faqs')) {
            return FaqPage;
        }

        if (search.includes('pages=create-assignment') && featureFlagAssignments) {
            return CreateAssignment;
        }

        if (search.includes('pages=past-assignments') && featureFlagAssignments) {
            return PastAssignments;
        }

        return HomePage;
    }, [featureFlagAssignments, search]);

    const showUserHeader = useMemo(
        () =>
            !search ||
            (!search.includes('pages=faqs') &&
                (!featureFlagAssignments ||
                    (!search.includes('pages=create-assignment') &&
                        !search.includes('pages=past-assignments')))),
        [featureFlagAssignments, search]
    );

    return (
        <PageContainer loading={isLoading}>
            <div ref={modalAuthRef} className="relative">
                <InitialBanner />
                <HeaderNav modalAuthRef={modalAuthRef} />
                {showUserHeader && <UserHeader modalAuthRef={modalAuthRef} />}
            </div>
            <RouteComponent />
            <div className={clsx({ 'show-overlay': modalOpen })} />
        </PageContainer>
    );
};
