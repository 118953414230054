import clsx from "clsx";
import "./About.scss";
import { ReactNode, RefObject, useCallback, useState } from "react";
import { useStore } from "src/store";
import { Modal } from "src/components/Modal";

interface AboutProps {
  aboutContent: ReactNode;
  anchorRef: RefObject<HTMLDivElement>;
  contentstyle?: string;
  lightButton?: boolean;
}

export const About = (props: AboutProps): JSX.Element => {
  const { aboutContent, anchorRef, contentstyle, lightButton } = props;

  const [setModalOpen] = useStore((state) => [state.setModalOpen]);
  const [open, setOpen] = useState(false);
  const toggleModal = useCallback(() => {
    setOpen(!open);
    setModalOpen();
  }, [open, setModalOpen]);

  return (
    <>
      <button
        className={clsx("about absolute", lightButton && "light-button")}
        onClick={toggleModal}
      >
        ?
      </button>
      <Modal
        open={open}
        contentStyle="max-w-largeModal"
        childrenStyle={contentstyle}
        anchorRef={anchorRef}
        onClose={toggleModal}
      >
        {aboutContent}
      </Modal>
    </>
  );
};
