export const AboutGameWordJumble = () => {
  return (
    <div className="grid md:grid-cols gap-4 py-4 mb-4">
      <div>
        <h5 className="mb-3">Word Jumble</h5>
        <p className="mb-4">Each of our learning games serves a specific purpose!</p>
        <p className="mb-4">
          Word Jumble Introduces the meaning, spelling, and pronunciation of each new word with
          jumbled syllables that must be placed in the right order to create a word! This is the
          critical first step of new word mastery.
        </p>
        <video width="100%" controls>
          <source
            src={`${process.env.REACT_APP_PAGE_URL}videos/construction.mp4`}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};
