import { ColorBox } from "../ColorBox";

export const AboutWordsOfWeek = () => (
  <div className="grid gap-4">
    <h5>Words in the bank!</h5>
    <p>
      This shows you the total number of words your child has learned playing
      Word Tag so far. Academics agree that the best vocabulary acquisition
      happens with spaced repetitions over time.
    </p>
    <ColorBox
      color="bg-boxColors-400"
      imageUrl={`${process.env.REACT_APP_PAGE_URL}icons/red_word.png`}
      text={
        <>
          When a word appears on this list, your child has interacted with it{" "}
          <strong className="text-modal">eight times</strong> over three weeks
          across different Word Games, tackling{" "}
          <strong className="text-modal">spelling</strong> and{" "}
          <strong className="text-modal">pronunciation</strong>,{" "}
          <strong className="text-modal">meaning</strong>, and use in{" "}
          <strong className="text-modal">context</strong>. But don&apos;t worry
          if they don&apos;t master a word instantly, some kids might need a few
          extra repetitions and our algorithm ensures that they get the exact
          practice they need!
        </>
      }
    />
    <p>
      Completing all these games means your child is ready to encounter that
      word in the real world. Take a look through all the words your child has
      learned so far. Try to use these words in conversation over breakfast, on
      the way to school, before bed, or any time at all. And use them in
      different contexts too, especially ones that your child is passionate
      about. This will reinforce learning and prove to your child just how far
      their vocabulary has come – and how proud they should be of their
      progress!
    </p>
  </div>
);
