import { createRef, HTMLAttributes, ReactNode, RefObject } from "react";
import clsx from "clsx";

import "./Container.scss";

import { About } from "src/components/About";

interface ContainerProps extends HTMLAttributes<HTMLDivElement> {
  aboutcontent?: ReactNode;
  rowref?: RefObject<HTMLDivElement>;
  modalContentStyle?: string;
  lightButton?: boolean;
}

export const Container = (props: ContainerProps): JSX.Element => {
  const { children, aboutcontent, rowref, modalContentStyle, lightButton } =
    props;

  const containerRef = createRef<HTMLDivElement>();

  return (
    <>
      <div
        ref={containerRef}
        className={clsx("about-button p-4 md:p-8 relative", props.className, {
          "md:pb-14 pb-14": !!aboutcontent,
        })}
      >
        {children}
        {!!aboutcontent && (
          <About
            aboutContent={aboutcontent}
            anchorRef={rowref || containerRef}
            contentstyle={modalContentStyle}
            lightButton={lightButton}
          />
        )}
      </div>
    </>
  );
};
