export const AboutWhy = () => (
  <div className="grid gap-4 pb-4">
    <h5>Did you know?</h5>
    <p>
      According to research, learners need several encounters with a word to add
      it to their vocabulary. Word Tag repeats word exposure enough times to
      guarantee retention.
    </p>
    <p>
      Additionally, Word Tag makes learning a positive experience. Playing every
      day creates a repeated, sustained cognitive connection between learning
      and feeling good.
    </p>
    <p>A word a day keeps the good vibes in play! </p>
  </div>
);
