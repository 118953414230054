import clsx from "clsx";
import { Button, Container } from "src/components";

import "./OpinionBanner.scss";

interface OpinionBannerProps extends React.HTMLAttributes<HTMLDivElement> {}

export const OpinionBanner = (props: OpinionBannerProps): JSX.Element => {
  const { className } = props;

  return (
    <Container
      className={clsx(
        "opinion-banner inline-flex flex-col gap-6 text-center items-center flex-grow justify-center min-h-[450px]",
        className
      )}
    >
      <div className="opinion-banner_image">
        <img
          src={`${process.env.REACT_APP_PAGE_URL}images/opinion_character_complete.png`}
          alt=""
        />
      </div>
      <h2>Not finding what you need, reach out</h2>
      <p className="text-xs">99% we have the information for you </p>
      <Button as="a" href={`mailto:${process.env.REACT_APP_OPINION_EMAIL}`}>
        <span className="text-sm">Click for more info or feedback</span>
      </Button>
    </Container>
  );
};
