import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import { useUserData } from "../hooks";

interface RouterContextValue {
  checkLoginStatus?: () => boolean;
  unauthorizedRedirectTo?: string;
}

export const RouterContext = React.createContext<RouterContextValue>({});

export const RouterProvider = ({
  children,
  ...props
}: RouterContextValue & { children: React.ReactNode }) => {
  useUserData();
  return (
    <Router>
      <RouterContext.Provider value={props}>{children}</RouterContext.Provider>
    </Router>
  );
};
