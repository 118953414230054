import createStore from "zustand";

import {
  Assignment,
  AssignmentHistory,
  Content,
  ContentData,
  ContentReport,
  ContentReportTypes,
  ContentWords,
  GameEvent,
  GameState,
  GameUser,
  NewAssignment,
  Report,
  User,
  WordList,
} from "src/types";

import * as DEMO from "./demo";
import GameItemsAssets from "src/assets/gamedata.json";

import { API } from "../modules";
interface Store {
  reports: Report;
  gameState: GameState;
  gameUsers: GameUser[];
  content: ContentWords;
  selectedGameUser: GameUser;
  user: User;
  fetchReport: () => void;
  fetchGameState: () => void;
  fetchGamesUsers: () => void;
  fetchContent: () => void;
  setGameUser: (gameUser: GameUser) => void;
  fetchAccount: () => void;
  fetchAccessEmail: (email: string) => void;
  setModalOpen: () => void;
  fetchNewWords: () => void;
  fetchWordListById: (id: string) => void;
  fetchWordLists: ({
    type,
    name,
    author,
  }: {
    type?: string;
    name?: string;
    author?: string;
  }) => void;
  fetchAssignments: ({ type, name }: { type?: string; name?: string }) => void;
  fetchAssignmentById: (assignmentId: string) => void;
  fetchAssignmentHistory: ({
    assignmentId,
    active,
    userGuid,
  }: {
    assignmentId: string;
    userGuid?: string;
    active?: boolean;
  }) => void;
  assignemntHistoryLoading: boolean;
  startAssignment: ({
    endDt,
    name,
    startDt,
    wordListId,
  }: NewAssignment) => void;
  finishAssignment: (
    assignmentId: string,
    assignment: {
      endDt: string;
      name: string;
    }
  ) => void;
  setWords: () => void;
  activeAssignments: Assignment[];
  assignmentHistory: AssignmentHistory | null;
  pastAssignments: Assignment[];
  idAssignment: Assignment;
  assignmentLoading: boolean;
  newWords: Content[];
  wordList: WordList;
  wordListLoading: boolean;
  wordLists: WordList[];
  wordListsTopics: string[];
  wordListsLoading: boolean;
  userLoading: boolean;
  reportsLoading: boolean;
  gameStateLoading: boolean;
  contentLoading: boolean;
  gameUsersLoading: boolean;
  words: ContentData;
  modalOpen: boolean;
  timePeriod: string;
  setTimePeriod: (timePeriod: string) => void;
}

const initialState = {
  reports: {
    message: [],
    starReceived: [],
    timePlayed: [],
    sessionStarted: [],
    activityCompleted: [],
    itemReceived: [],
    achievementEarned: [],
    missionFinished: [],
    missionStarted: [],
    missionAborted: [],
    wordCompleted: [],
  } as Report,
  gameState: {
    starCount: 0,
    coinCount: 0,
    canCount: 0,
    gearCount: 0,
    wordsCompletedCount: 0,
    wordsInProgressCount: 0,
  } as GameState,
  content: {
    AssembleSyllables: [],
    MatchCollocations: [],
    MatchSentenceBlank: [],
    MatchSynonyms: [],
    PopQuiz: [],
    PopQuizSynonym: [],
    PopQuizCollocation: [],
  } as ContentWords,
  gameUsers: [] as GameUser[],
  selectedGameUser: {} as GameUser,
  user: {} as User,
  userLoading: true,
  reportsLoading: true,
  gameStateLoading: true,
  contentLoading: true,
  gameUsersLoading: true,
  modalOpen: false,
  words: {} as ContentData,
  timePeriod: "7-days",
  newWords: [],
  wordList: {} as WordList,
  wordListLoading: true,
  wordLists: [],
  wordListsTopics: [],
  wordListsLoading: true,
  activeAssignments: [],
  pastAssignments: [],
  idAssignment: {} as Assignment,
  assignmentLoading: false,
  assignmentHistory: null,
  assignemntHistoryLoading: false,
};

const reportsTreatment = (data: GameEvent[]) => {
  const types = data.map((item) => item.type);
  const keys = types.filter((item, pos) => types.indexOf(item) === pos);
  let reports = {} as Report;
  for (const eachKey of keys) {
    const filtered = data.filter((event) => event.type === eachKey);
    reports[eachKey] = filtered;
  }
  if (reports?.itemReceived) {
    reports.itemReceived = reports.itemReceived.filter(
      (item) =>
        (item.data.item?.id as string) !== "" &&
        item.data.item?.id &&
        GameItemsAssets.items[item.data.item.id]?.icon
    );
  }
  return reports;
};

const contentTreatment = (data: GameEvent[]): ContentWords => {
  const types = data.map((item) => item.data?.activity) as ContentReportTypes[];
  const keys = types.filter((item, pos) => types.indexOf(item) === pos);

  let content = {
    AssembleSyllables: [],
    MatchCollocations: [],
    MatchSentenceBlank: [],
    MatchSynonyms: [],
    PopQuiz: [],
    PopQuizSynonym: [],
    PopQuizCollocation: [],
  } as ContentReport;

  for (const eachKey of keys) {
    const filteredByActivity = data.filter(
      (event) => event.data?.activity === eachKey
    );
    const filtered = [
      ...new Map(
        filteredByActivity.map((item) => [item.data.wordId, item])
      ).values(),
    ];
    if (content[eachKey]) content[eachKey] = filtered;
  }

  return {
    AssembleSyllables: content.AssembleSyllables,
    MatchCollocations: content.MatchCollocations,
    MatchSentenceBlank: content.MatchSentenceBlank,
    MatchSynonyms: content.MatchSynonyms,
    PopQuiz: content.PopQuiz,
    PopQuizSynonym: content.PopQuizSynonym,
    PopQuizCollocation: content.PopQuizCollocation,
  };
};

export const useStore = createStore<Store>((set, get) => ({
  ...initialState,
  fetchAccount: async () => {
    try {
      set({ userLoading: true });
      const data = await API.getAccount();
      set({ user: data, userLoading: false });
    } catch (error: any) {
      const reports = reportsTreatment(DEMO.REPORTS);
      const content = contentTreatment(DEMO.REPORTS);
      set({
        userLoading: false,
        reportsLoading: false,
        gameStateLoading: false,
        contentLoading: false,
        gameUsersLoading: false,
        reports: { ...initialState.reports, ...reports },
        gameState: DEMO.GAME_STATE,
        gameUsers: DEMO.GAME_USERS,
        selectedGameUser: DEMO.GAME_USERS[0],
        content,
        words: DEMO.WORDS,
        newWords: Object.values(DEMO.WORDS).slice(20, 50),
      });
      throw error.message;
    }
  },
  setWords: () => {
    set({ words: DEMO.WORDS });
  },
  setGameUser: (user: GameUser) => {
    set({ selectedGameUser: user });
  },
  setModalOpen: () => {
    set({ modalOpen: !get().modalOpen });
  },
  setTimePeriod: (timePeriod: string) => {
    set({ timePeriod: timePeriod });
  },
  fetchGamesUsers: async () => {
    try {
      set({ gameUsersLoading: true });
      const data = await API.getUserData();
      set({
        gameUsers: data,
        selectedGameUser: data[0],
        gameUsersLoading: false,
      });
    } catch (error: any) {
      set({ gameUsersLoading: false });
      throw error.message;
    }
  },
  fetchReport: async () => {
    try {
      set({ reportsLoading: true });
      const userId = get().selectedGameUser.guid;
      const timePeriod = get().timePeriod;
      const data = await API.getReport(userId, timePeriod);
      const reports = reportsTreatment(data);
      set({
        reports: { ...initialState.reports, ...reports },
        reportsLoading: false,
      });
    } catch (error: any) {
      set({ reportsLoading: false });
      throw error.message;
    }
  },
  fetchGameState: async () => {
    try {
      set({ gameStateLoading: true });
      const userId = get().selectedGameUser.guid;
      const data = await API.getGameState(userId);
      set({ gameState: data, gameStateLoading: false });
    } catch (error: any) {
      set({ gameStateLoading: false });
      throw error.message;
    }
  },
  fetchContent: async () => {
    try {
      set({ contentLoading: true });
      const activityCompleted = get().reports.activityCompleted;
      const activities = contentTreatment(activityCompleted);

      const wordIds: string[] = activityCompleted.map(
        (activity) => activity.data.wordId ?? ""
      );

      const filteredWordIds = wordIds.filter(
        (item, pos) => wordIds.indexOf(item) === pos
      );

      const data = await API.getContent(filteredWordIds);

      const populatedData =
        activityCompleted.map((item) => {
          const contentDataIndex = data.findIndex(
            (content) => content.id === item.data.wordId
          );

          return {
            ...data[contentDataIndex],
            activity: item.data.activity,
            success: item.data.success ?? true,
            attemptCount: item.data.attemptCount ?? 0,
            eventDt: item.eventDt,
          };
        }) ?? [];

      const enumWords: ContentData = {};

      for (const eachKey of populatedData) {
        if (eachKey.word) {
          enumWords[`${eachKey.id}-${eachKey.activity}`] = eachKey;
        }
      }

      set({ content: activities, words: enumWords, contentLoading: false });
    } catch (error: any) {
      set({ contentLoading: false });
      throw error.message;
    }
  },
  fetchAccessEmail: async (email: string) => {
    try {
      const data = await API.getAccessEmail(email);
      return data;
    } catch (error: any) {
      throw error.message;
    }
  },
  fetchNewWords: async () => {
    try {
      const userId = get().selectedGameUser.guid;
      const timePeriod = get().timePeriod;
      const data = await API.getNewWords(userId, timePeriod);
      const filteredWordIds = data.map((item) => item.wordId);

      const newWords = await API.getContent(filteredWordIds);

      set({ newWords: newWords });
    } catch (error: any) {
      throw error.message;
    }
  },
  fetchWordListById: async (id) => {
    try {
      set({ wordListLoading: true });
      const data = await API.getWordListById(id);
      set({ wordList: data, wordListLoading: false });
    } catch (error: any) {
      set({ wordListLoading: false });
      throw error.message;
    }
  },
  fetchWordLists: async ({ type, name, author }) => {
    try {
      set({ wordListsLoading: true });

      const data = await API.getWordlists({ type, name, author });

      set((state) => {
        const oldTopics = state.wordListsTopics;

        const newTopics = data.map((item) => item.type);

        const topics = [...new Set([...oldTopics, ...newTopics])];

        return {
          wordListsTopics: topics,
          wordLists: data,
          wordListsLoading: false,
        };
      });
    } catch (error: any) {
      set({ wordListsLoading: false });
      throw error.message;
    }
  },
  startAssignment(data) {
    try {
      API.startAssignment({
        ...data,
        userIds: [get().selectedGameUser.guid],
      }).then(() => {
        window.location.href = "/";
      });
    } catch (error: any) {
      throw error.message;
    }
  },
  finishAssignment: async (assignmentId, assignment) => {
    try {
      await API.finishAssignment(assignmentId, assignment);
    } catch (error: any) {
      throw error.message;
    }
  },
  fetchAssignmentHistory: async ({ assignmentId, active }) => {
    try {
      set({ assignemntHistoryLoading: true });
      const assignmnetHistory = await API.getAssignmentHistory(
        assignmentId,
        get().selectedGameUser.guid,
        active
      );

      set({
        assignmentHistory: assignmnetHistory,
        assignemntHistoryLoading: false,
      });
    } catch (error: any) {
      set({ assignemntHistoryLoading: false });
      throw error.message;
    }
  },
  fetchAssignmentById: async (id) => {
    try {
      const assignment = await API.getAssignmentById(id);

      set({ idAssignment: assignment });
    } catch (error: any) {
      throw error.message;
    }
  },
  fetchAssignments: async ({ type, name }) => {
    try {
      const assignments = await API.getAssignments({
        userGuid: get().selectedGameUser.guid,
        type,
        name,
      });

      const activeAssignments = assignments?.filter(
        (assignment) => new Date(assignment.endDt) < new Date()
      );

      const pastAssignments = assignments?.filter(
        (assignment) => new Date(assignment.endDt) > new Date()
      );

      set({
        activeAssignments,
        pastAssignments,
        assignmentLoading: false,
      });
    } catch (error: any) {
      set({ assignmentLoading: false });
      throw error.message;
    }
  },
}));
