import { createRef, useCallback, useEffect, useMemo, useState } from "react";
import { WordListGrid } from "../WordListGrid";
import { StatisticsWidgets } from "../StatisticsWidget";
import { Container } from "../Structures";
import { WordWidget } from "../WordWidget";

import "./Assignment.scss";
import { AssignmentProps } from "src/types";
import useAssignmentsHook from "src/hooks/use-assignments.hook";
import { ThreeBody } from "@uiball/loaders";
import { useStore } from "src/store";

export const Assignment = (props: AssignmentProps): JSX.Element => {
  const { assignment, header } = props;
  const { name } = assignment;
  const {
    fetchWordListById,
    wordList,
    fetchAssignmentHistory,
    assignmentHistory,
    assignemntHistoryLoading,
    wordListLoading,
  } = useAssignmentsHook();
  const [user] = useStore((state) => [state.selectedGameUser]);
  const modalRef = createRef<HTMLDivElement>();

  useEffect(() => {
    fetchWordListById(assignment.wordListId);
    fetchAssignmentHistory({
      assignmentId: assignment.id,
    });
  }, [
    assignment.wordListId,
    fetchWordListById,
    fetchAssignmentHistory,
    assignment.id,
  ]);

  const [wordListVisible, setWordListVisible] = useState(false);

  const icon = useMemo(() => {
    return {
      src: `${process.env.REACT_APP_PAGE_URL}icons/red_word.png`,
      alt: "word",
    };
  }, []);

  const toggleWordList = useCallback(
    () => setWordListVisible((prevState) => !prevState),
    []
  );

  const {
    wordCompletionCount,
    averageTimePlayedPerDay,
    successOnFirstAttemptCount,
  } = useMemo(
    () => ({
      wordCompletionCount:
        assignmentHistory?.users?.[user.guid]?.wordCompletionCount ?? 0,
      averageTimePlayedPerDay:
        assignmentHistory?.users?.[user.guid]?.averageTimePlayedPerDay ?? 0,
      successOnFirstAttemptCount:
        assignmentHistory?.users?.[user.guid]?.successOnFirstAttemptCount ?? 0,
    }),
    [assignmentHistory, user.guid]
  );

  return (
    <Container className="assignment-container">
      {header ? header : null}
      <div
        className="flex gap-6 mb-4 assignment-container__first-section"
        ref={modalRef}
      >
        {assignemntHistoryLoading || wordListLoading ? (
          <div className="absolute w-full h-full left-0 top-0  flex justify-center items-center">
            <ThreeBody size={35} speed={1.1} color="black" />
          </div>
        ) : (
          <>
            {assignmentHistory ? (
              <>
                <div className="w-[35%] assignment-container__word-widget">
                  <WordWidget
                    title={name}
                    subtitle={wordList.author}
                    btnLabel={
                      wordListVisible ? "Hide Word List" : "View Word List"
                    }
                    onClick={toggleWordList}
                    icon={icon}
                    btnVariant={wordListVisible ? "light" : "dark"}
                    endDt={assignment.endDt}
                    startDt={assignment.startDt}
                    wordsCompleted={wordCompletionCount}
                    wordsTotal={wordList?.words?.length ?? 0}
                  />
                </div>
                <div className="assignment-container__statistics">
                  <StatisticsWidgets
                    completionExpectedScore={
                      assignmentHistory?.expectedWordCompletionCount
                    }
                    completionScore={wordCompletionCount}
                    completionTotal={wordList?.words?.length ?? 0}
                    accuracyExpectedScore={
                      assignmentHistory?.expectedSuccessOnFirstAttemptCount
                    }
                    accuracyScore={successOnFirstAttemptCount}
                    accuracyTotal={wordList?.words?.length ?? 0}
                    timeExpectedScore={
                      assignmentHistory?.minimumExpectedPlayTimePerDay
                    }
                    timeScore={averageTimePlayedPerDay}
                    timeTotal={assignmentHistory?.minimumExpectedPlayTimePerDay}
                  />
                </div>
              </>
            ) : null}
          </>
        )}
      </div>
      {wordListVisible && <WordListGrid wordList={wordList} />}
    </Container>
  );
};
