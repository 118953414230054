export const AboutGameSynonymsOfWordPairs = () => {
  return (
    <div className="grid md:grid-cols gap-4 py-4 mb-4">
      <div>
        <h5 className="mb-3">Synonyms or Word Pairs</h5>
        <p className="mb-4">Each of our learning games serves a specific purpose!</p>
        <p className="mb-4">
          Words in Context Gives kids a chance to use words in context by choosing the right word to
          complete a sentence. The skill combines their ability to recognize the word, and
          understand its meaning in context.
        </p>
        <video width="100%" controls>
          <source src={`${process.env.REACT_APP_PAGE_URL}videos/synonym.mp4`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};
