import { ThreeBody } from "@uiball/loaders";

interface PageContainerProps {
  children?: React.ReactNode;
  loading?: boolean;
}

export const PageContainer = (props: PageContainerProps): JSX.Element => {
  const { children, loading } = props;
  if (loading)
    return (
      <div className="absolute w-full h-full left-0 top-0  flex justify-center items-center">
        <ThreeBody size={35} speed={1.1} color="black" />
      </div>
    );
  return <>{children}</>;
};
