import React from "react";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import "./store";
import "./style.scss";

import { RouterMap, RouterProvider } from "./core";
import { routerConfiguration } from "./router-configuration";

import { createRoot } from "react-dom/client";
const container = document.getElementById("root");
const root = createRoot(container!);

Sentry.init({
  dsn: process.env.VITE_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

root.render(
  <RouterProvider>
    <RouterMap routes={routerConfiguration} />
  </RouterProvider>
);
