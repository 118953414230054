import { useEffect } from "react";
import { useStore } from "src/store";

const useAssignmentsHook = () => {
  const [fetchAssignments] = useStore((state) => [state.fetchAssignments]);
  const [fetchAssignmentById] = useStore((state) => [
    state.fetchAssignmentById,
  ]);
  const [startAssignment] = useStore((state) => [state.startAssignment]);
  const [fetchWordLists] = useStore((state) => [state.fetchWordLists]);
  const [activeAssignments] = useStore((state) => [state.activeAssignments]);
  const [pastAssignments] = useStore((state) => [state.pastAssignments]);
  const [idAssignment] = useStore((state) => [state.idAssignment]);
  const [wordLists, wordListsTopics, wordListsLoading] = useStore((state) => [
    state.wordLists,
    state.wordListsTopics,
    state.wordListsLoading,
  ]);
  const [finishAssignment] = useStore((state) => [state.finishAssignment]);

  const [wordList, fetchWordListById, wordListLoading] = useStore((state) => [
    state.wordList,
    state.fetchWordListById,
    state.wordListLoading,
  ]);
  const [fetchAssignmentHistory, assignmentHistory, assignemntHistoryLoading] =
    useStore((state) => [
      state.fetchAssignmentHistory,
      state.assignmentHistory,
      state.assignemntHistoryLoading,
    ]);

  useEffect(() => {
    fetchAssignments({});
  }, [fetchAssignments, fetchAssignmentById]);

  return {
    activeAssignments,
    pastAssignments,
    idAssignment,
    wordLists,
    wordListsTopics,
    wordListsLoading,
    fetchWordLists,
    startAssignment,
    wordList,
    fetchWordListById,
    fetchAssignmentHistory,
    assignmentHistory,
    assignemntHistoryLoading,
    wordListLoading,
    fetchAssignments,
    finishAssignment,
  };
};

export default useAssignmentsHook;
