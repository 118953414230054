export const AboutGameWordsInContext = () => {
  return (
    <div className="grid md:grid-cols gap-4 py-4 mb-4">
      <div>
        <h5 className="mb-3">Words in Context</h5>
        <p className="mb-4">Each of our learning games serves a specific purpose!</p>
        <p className="mb-4">
          The Word Pair Game reinforces word meaning by bringing in synonyms and word pairs. This
          critical piece of the learning process ensures the learners truly understand the new word,
          not just recognizes it.
        </p>
        <video width="100%" controls>
          <source src={`${process.env.REACT_APP_PAGE_URL}videos/sentence.mp4`} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};
