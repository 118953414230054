import moment from "moment";
import React from "react";

interface PopQuizLineProps {
  event: string;
  successRate: number;
}

export const PopQuizLine = ({
  event,
  successRate,
}: PopQuizLineProps): JSX.Element => {
  const lampImg = (
    <img
      className="w-9"
      src={`${process.env.REACT_APP_PAGE_URL}icons/lamp.png`}
      alt=""
    />
  );
  return (
    <>
      {lampImg}
      <div className="flex flex-col w-full justify-between">
        <div className="flex justify-between">
          <h6 className="self-center">{moment(event).format("DD MMM YYYY")}</h6>
          <div className="flex flex-nowrap">
            <h6 className="pr-4 self-center">
              {Math.round(successRate * 100)}%
            </h6>
          </div>
        </div>
      </div>
    </>
  );
};
