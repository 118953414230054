export type EarnableType = "star" | "gear" | "coin" | "can" | "word";

export type EarnableItem = {
  type: EarnableType;
  title: string;
  total: number;
};

export enum ItemType {
  None = 0,
  Currency = 1,
  Hat = 2,
  Outfit = 3,
  Backpack = 4,
  Vehicle = 5,
  Bundle = 6,
  Food = 7,
  Area = 8,
  Shoe = 9,
  Character = 10,
}
