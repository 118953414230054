import { PropsWithChildren, ReactNode, RefObject, createRef } from "react";
import clsx from "clsx";
import "./Box.scss";
import { About } from "src/components/About";

interface BoxProps {
  className?: string;
  contentstyle?: string;
  onClick?: () => void;
  aboutcontent?: ReactNode;
  rowref?: RefObject<HTMLDivElement>;
  modalContentStyle?: string;
  lightButton?: boolean;
}

export const Box = (props: PropsWithChildren<BoxProps>): JSX.Element => {
  const {
    className,
    contentstyle,
    children,
    aboutcontent,
    rowref,
    modalContentStyle,
    lightButton,
  } = props;
  const containerRef = createRef<HTMLDivElement>();

  return (
    <div
      {...props}
      className={clsx("card-box p-4 relative", className, contentstyle)}
      ref={containerRef}
    >
      {children}
      {!!aboutcontent && (
        <About
          aboutContent={aboutcontent}
          anchorRef={rowref || containerRef}
          contentstyle={modalContentStyle}
          lightButton={lightButton}
        />
      )}
    </div>
  );
};
