import { ReactNode, RefObject, createRef, useState } from "react";
import {
  DonutChart,
  ItemList,
} from "src/modules/Home/pages/HomePage/components";
import { Content, PopQuizByDate } from "src/types";
import { PopQuizItemList } from "../PopQuizItemList";
import { Box } from "../Structures";
import "./GameWidget.scss";

interface GameWidgetProps {
  icon: JSX.Element;
  title: string;
  data: Array<Content>;
  popQuizData?: PopQuizByDate;
  aboutContent?: ReactNode;
  wrapperRef?: RefObject<HTMLDivElement> | undefined;
}

export const GameWidget = ({
  icon,
  title,
  aboutContent,
  wrapperRef,
  data,
  popQuizData,
}: GameWidgetProps) => {
  const modalRef = createRef<HTMLDivElement>();
  const [open, setOpen] = useState(false);
  const toggleModal = () => {
    setOpen(!open);
  };
  const checkIcon = `${process.env.REACT_APP_PAGE_URL}icons/check.svg`;

  const alertIcon = `${process.env.REACT_APP_PAGE_URL}icons/alert.svg`;

  const totalNumberOfWords = data.length;
  const numberOfSuccess = data.filter(
    (el) => el.attemptCount && el.attemptCount < 3
  ).length;

  const totalDaysPopQuiz = popQuizData ? Object.keys(popQuizData).length : 0;
  const successRatePopQuiz = popQuizData
    ? Object.values(popQuizData).map(
        (day) => day.filter((game) => game.success).length / day.length
      )
    : [];

  const containerRef = () => {
    const screenSize = window.innerWidth;
    if (screenSize > 768) return wrapperRef;
    return modalRef;
  };

  return (
    <Box
      className="flex flex-col w-1/2 mdMobile:w-full smMobile:w-full mobile:w-full"
      modalContentStyle="smMobile:max-h-[320px] mobile:max-h-[320px]"
      aboutcontent={aboutContent}
      rowref={containerRef()}
    >
      <div className="flex w-full items-center" ref={modalRef}>
        {icon} <h6> {title} </h6>
      </div>
      <div className="flex w-full items-center justify-between flex-wrap mobile:justify-around laptop:justify-around">
        <div
          onMouseEnter={toggleModal}
          onMouseLeave={toggleModal}
          className="cursor-pointer relative"
        >
          <DonutChart
            score={
              popQuizData
                ? successRatePopQuiz.filter((day) => day >= 0.7).length
                : numberOfSuccess
            }
            total={popQuizData ? totalDaysPopQuiz : totalNumberOfWords}
            middleNumber={popQuizData ? totalDaysPopQuiz : totalNumberOfWords}
            middleText="played"
            className="text-7xl my-2 mx-auto"
          />

          {open && (
            <div className="absolute top-[-5px] right-[-18px] ">
              <Box className="flex flex-col text-xs !p-1">
                <div className="flex items-center justify-center">
                  <img className="w-3 h-3" src={checkIcon} alt="check" /> :{" "}
                  {popQuizData
                    ? successRatePopQuiz.filter((day) => day >= 0.7).length
                    : numberOfSuccess}
                </div>

                <div className="flex items-center justify-center">
                  <img className="w-3 h-3" src={alertIcon} alt="alert" /> :{" "}
                  {popQuizData
                    ? totalDaysPopQuiz -
                      successRatePopQuiz.filter((day) => day < 0.7).length
                    : totalNumberOfWords - numberOfSuccess}
                </div>
              </Box>
            </div>
          )}
        </div>
        <div className="flex-column  mdMobile:w-[200px] mobile:w-1/2 lgMobile:w-[200px]  mdLaptop:w-[220px] laptop:w-[270px]">
          <div className="label">
            <h6>Succeeded</h6>
            <img className="w-6 h-6" src={checkIcon} alt="check" />
          </div>
          <div className="label">
            <h6>Needs Practice</h6>
            <img className="w-6 h-6" src={alertIcon} alt="alert" />
          </div>
        </div>
      </div>

      {popQuizData ? (
        <PopQuizItemList popQuizData={popQuizData} className="itemListWidget" />
      ) : (
        <ItemList data={data} className="itemListWidget" isGameWidget />
      )}
    </Box>
  );
};
