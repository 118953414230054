import { createRef, useMemo } from "react";
import { OpinionBanner } from "src/components/Structures/OpinionBanner";

import { LatestEarnings, StarsCollected, WordsOfWeek } from "./components";

import "./HomePage.scss";
import { TimePeriodBar } from "./components/TimePeriodBar";
import { NewWords } from "./components/NewWords";
import { CreateAssignment } from "./components/CreateAssignment";
import { WorkOnWordGames } from "./components/WorkOnWordGames";
import { ActiveAssignment } from "./components/ActiveAssignment";
import { useStore } from "src/store";
import clsx from "clsx";
import useAssignmentsHook from "src/hooks/use-assignments.hook";

export const HomePage = (): JSX.Element => {
  const featureFlagAssignments = process.env.REACT_APP_FEATURE_ASSIGNMENTS;
  const wordsOfWeekRef = createRef<HTMLDivElement>();
  const [user] = useStore((state) => [state.user]);
  const { activeAssignments } = useAssignmentsHook();
  const { pastAssignments } = useAssignmentsHook();

  const loggedUserWidgets = useMemo(() => user && user.id, [user]);

  const hasPastAssignment = useMemo(
    () => pastAssignments && pastAssignments.length > 0,
    [pastAssignments]
  );

  const hasActiveAssignment = useMemo(
    () => activeAssignments && activeAssignments.length > 0,
    [activeAssignments]
  );

  return (
    <main
      className={clsx(
        "container p-4 md:p-0 my-4 md:my-16 mx-auto gap-2 md:gap-4"
      )}
    >
      <div className="time-period-container">
        <TimePeriodBar />
      </div>
      <div className="main-widgets-container gap-2 md:gap-4">
        {featureFlagAssignments === "true" && loggedUserWidgets ? (
          <div className="grid gap-2 md:gap-4">
            {hasActiveAssignment ? (
              <div className="active-assignment-container">
                <ActiveAssignment hasPastAssignment={hasPastAssignment} />
              </div>
            ) : (
              <div className="create-assignment-container">
                <CreateAssignment hasPastAssignment={hasPastAssignment} />
              </div>
            )}
          </div>
        ) : null}
        <div className="work-on-word-games-container" ref={wordsOfWeekRef}>
          <WorkOnWordGames />
        </div>
      </div>
      <div className="stars-collected-container">
        <StarsCollected />
      </div>
      <div className="earning-container">
        <LatestEarnings />
      </div>
      <div className="words-collected-container gap-2 md:gap-4">
        <NewWords rowRef={wordsOfWeekRef} />
        <WordsOfWeek rowRef={wordsOfWeekRef} />
        <OpinionBanner />
      </div>
    </main>
  );
};
