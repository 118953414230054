import clsx from "clsx";
import React from "react";
import { Link, LinkProps } from "react-router-dom";

import "./Button.scss";

interface Props {
  children: React.ReactNode;
  variant?: "dark" | "light";
  icon?: string;
  as?: "button" | "div" | "link" | "a";
  to?: LinkProps["to"];
  rel?: string;
  href?: string;
  target?: string;
}

type TDiv = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

type THref = React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>;

type TButton = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

type TWrapper<T> = Props & T;

type WrapperProps = TWrapper<TDiv & TButton>;

const ButtonWrapper = (props: TWrapper<TButton>): JSX.Element => (
  <button {...props}>{props.children}</button>
);

const DivWrapper = (props: TWrapper<TDiv>): JSX.Element => (
  <div {...props}>{props.children}</div>
);

const HrefWrapper = (props: TWrapper<THref>): JSX.Element => (
  <a {...props} href={props.href}>
    {props.children}
  </a>
);

const LinkWrapper = (props: TWrapper<LinkProps>): JSX.Element => (
  <Link {...props} to={props.to}>
    {props.children}
  </Link>
);

const Wrapper = (props: WrapperProps): JSX.Element => {
  const {
    as,
    children,
    variant = "dark",
    className,
    to,
    disabled,
    href,
  } = props;

  switch (as) {
    case "div":
      return (
        <DivWrapper
          {...props}
          className={clsx("button", variant, className, { disabled })}
        >
          {children}
        </DivWrapper>
      );
    case "link":
      return (
        <LinkWrapper to={to || "/"}>
          <div
            {...props}
            className={clsx("button", variant, className, { disabled })}
          >
            {children}
          </div>
        </LinkWrapper>
      );
    case "a":
      const { rel, target } = props;
      return (
        <HrefWrapper
          rel={rel}
          target={target}
          href={href || "/"}
          className={clsx("button", variant, className)}
        >
          {children}
        </HrefWrapper>
      );
    default:
      return (
        <ButtonWrapper
          {...props}
          className={clsx("button", variant, className, { disabled })}
        >
          {children}
        </ButtonWrapper>
      );
  }
};

export const Button = (props: WrapperProps): JSX.Element => {
  const { icon, children } = props;
  return (
    <Wrapper {...props}>
      {children}
      {icon && <img src={icon} alt={icon} />}
    </Wrapper>
  );
};
